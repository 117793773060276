import React, { useState } from "react";
import styled from "@emotion/styled";

import { RouteComponentProps } from "react-router";
import { usePrograms } from "../../context/Programs";
import { FaPen, FaTrash, FaDownload } from "react-icons/fa";
import { ICourse, IProgram } from "../../context/Programs/program.type";
import { useCancelablePromise } from "../../utils/hooks";
import { Link } from "react-router-dom";
import { cx } from "../../utils/classnames";
import { PracticesList } from "../Practices/practiceList";
import { getDocuments } from "../../context/Programs/utils";
import { IDocument } from "../../context/Programs/document.type";
import { Box, ImageContainer } from "../Practice";
import { ProgramList } from "./Programs/programsList";

interface Props extends RouteComponentProps<{ courseId: string }> {}

export const Course: React.SFC<Props> = ({ history, match: { params } }) => {
  const {
    state: { loading },
    action: { getCourse, deleteCourse }
  } = usePrograms();

  const [course, setCourse] = useState<ICourse | null>(null);

  useCancelablePromise(() => getCourse(params.courseId), setCourse, () =>
    history.goBack()
  );

  const _deleteCourse = () => {
    if (course) {
      deleteCourse(course._id, () => {
        history.goBack()
      })
    }
  }

  const hasImage = course && course.image_url && course.image_url.original;

  return course ? (
    <div className="container is-fluid">
      <h2 className="title is-3 has-text-white">{course.title}</h2>
      <div className="columns">
        <div className="column is-narrow">
          <Box>
            <ImageContainer>
              {hasImage && <img src={course.image_url.original} alt="ahhh" />}
            </ImageContainer>
          </Box>
        </div>
        <div className="column">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <span
                  className={cx("tag is-medium", {
                    "is-link": course.type === "paid",
                    "is-success": course.type === "free"
                  })}
                >
                  {course.type}
                </span>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <Link
                  to={{
                    pathname: `/programs/edit/${course._id}`,
                    state: { course }
                  }}
                  className="button is-light"
                >
                  <span className="icon">
                    <FaPen />
                  </span>
                  <span>Editar</span>
                </Link>
              </div>
              <div className="level-item">
                <button
                  className="button has-text-weight-semibold"
                  disabled={loading}
                  onClick={_deleteCourse}
                >
                  <span className="icon">
                    <FaTrash />
                  </span>
                  <span>Excluir</span>
                </button>
              </div>
            </div>
          </div>
          <Container>
            <h1 className="title is-6">Descrição</h1>
            <p className="subtitle">{course.description}</p>
          </Container>

          <Container>
            <h1 className="title is-6">Sessões</h1>
            <ProgramList courseId={params.courseId} programs={course.programs} />
          </Container>
        </div>
      </div>
    </div>
  ) : null;
};

const Container = styled.div`
  margin-bottom: 3.5rem;
`;

const Table = styled.table`
  & td {
    border: none;
    cursor: pointer;
  }
`;

Table.defaultProps = { className: "table is-hoverable" };
