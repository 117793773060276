import React, { useMemo, useCallback, useState } from "react";
import styled from "@emotion/styled";
import { usePrograms } from "../../../context/Programs";
import {
  useDebounce,
  useInput,
  useDebounceCallback
} from "../../../utils/hooks";
import { PracticeInput } from "./practiceInput";
import { FaSearch, FaTimes } from "react-icons/fa";

interface Props {
  isOpen: boolean;
  close(): void;
  selection: string[];
  updateCallback(a: string[]): void;
}

export const SelectPracticeModal: React.SFC<Props> = React.memo(
  ({ isOpen, updateCallback, selection, close }) => {
    const {
      state: { practices }
    } = usePrograms();

    const { value, onChange } = useInput("");

    const debouncedValue = useDebounce(value, 500);

    const data = useMemo(() => {
      let _result = practices;
      if (debouncedValue.length > 0) {
        const regex = new RegExp(debouncedValue, "i");
        _result = _result.filter(p => regex.test(p.title));
      }
      return _result.map(practice => ({
        ...practice,
        checked: selection.includes(practice._id)
      }));
    }, [debouncedValue, practices, selection]);

    const [selected, changeSelection] = useState<string[]>([]);
    const onSelect = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (selected.includes(value)) {
          changeSelection(prev => prev.filter(p => p !== value));
        } else {
          changeSelection(prev => [...prev, value]);
        }
      },
      [selected]
    );

    useDebounceCallback(selected, 200, selected => {
      updateCallback(selected);
    });

    return isOpen ? (
      <div
        className="modal is-active"
        style={{ overscrollBehaviorY: "contain" }}
      >
        <div className="modal-background" onClick={close} />

        <Wrapper>
          <CloseButton onClick={close}>
            <FaTimes />
          </CloseButton>

          <Header>
            <h1 className="title is is-size-5-mobile has-text-grey">
              Selecione as Práticas
            </h1>

            <div className="control has-icons-right">
              <input
                className="input"
                type="text"
                placeholder="Buscar"
                value={value}
                onChange={onChange}
              />
              <span className="icon is-small is-right">
                <FaSearch />
              </span>
            </div>
          </Header>

          <Container>
            {data.map(practice => (
              <PracticeInput
                key={practice._id}
                onChange={onSelect}
                practice={practice}
                checked={practice.checked}
              />
            ))}
          </Container>
        </Wrapper>
      </div>
    ) : null;
  }
);

const Wrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 4px;
  overflow: hidden;
  max-height: 100%;
  position: relative;

  @media screen and (min-width: 769px) {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 640px;
  }
`;

const CloseButton = styled.button`
  background: transparent;
  cursor: pointer;
  appearance: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #bdbdbd;
  font-size: 1.4rem;
  right: 1.2rem;
  top: 1.2rem;
`;

const Header = styled.div`
  padding: 1rem;
  flex-shrink: 0;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const Container = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  background: white;
  padding: 1rem;
`;
