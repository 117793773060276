import React from "react";
import styled from "@emotion/styled";
import { FaCheck } from "react-icons/fa";

interface Props {
  name: string;
  value: any;
  options: { label: string; value: any }[];
  onChange(e: any): void;
}

export const CheckBox: React.SFC<Props> = ({ options, name, value, onChange }) => {
  return (
    <Container>
      {options.map((option, key) => (
        <Label key={key}>
          <span>
            <Input
              hidden
              type="radio"
              name={name}
              value={option.value}
              onChange={onChange}
              checked={value === option.value}
            />
            <RadioButton className="radio-value" aria-hidden="true">
              <FaCheck />
            </RadioButton>
          </span>
          {option.label}
        </Label>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  cursor: pointer;

  & + & {
    margin-left: 1rem;
  }
`;

const RadioButton = styled.span`
  background-color: #fff;
  border-radius: 50%;
  border: 0.0625em solid #bbb;
  box-shadow: inset 0 0.0625em 0 0.0625em rgba(0, 0, 0, 0.075);
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  margin-right: 0.25rem;
  font-size: 1rem;
  color: white;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  & svg {
    opacity: 0;
  }
`;

const Input = styled.input`
  &:checked + ${RadioButton} {
    background-color: #3273dc;
  }

  &:checked + ${RadioButton} svg {
    opacity: 1;
  }
`;
