import React, { useRef, useState, useEffect, useCallback } from "react"
import styled from "@emotion/styled"
import { FaPause, FaPlay } from "react-icons/fa"
import { useToggle } from "../../utils/hooks"
import { getUrl, getDuration } from "../../utils/utils"

function toPercentage(current: number, total: number) {
  return (current / total) * 100
}

interface Props {
  file: string | File | null
  filename?: string
  setAudioDuration?: (durtion: number) => void
}

export const Player: React.SFC<Props> = React.memo(({ file, filename, setAudioDuration }) => {
  const buffer = useRef<number>(0)
  const timer = useRef<HTMLInputElement>(null)
  // const audio = useRef<HTMLAudioElement>(null)

  const { active: loading, activate: startLoading, disable: endLoading } = useToggle(false)
  const { active: isPlaying, activate: startPlay, disable: endPlay } = useToggle(false)
  const [duration, setDuration] = useState("00:00:00")

  const audio = useRef(new Audio())

  useEffect(() => {
    audio.current.onloadedmetadata = onLoadedMetadata
    audio.current.onplay = startPlay
    audio.current.onpause = endPlay
    audio.current.onprogress = onProgress
    audio.current.onloadstart = startLoading
    audio.current.ontimeupdate = onTimeUpdate
    audio.current.onloadedmetadata = onLoadedMetadata

    return () => {
      audio.current.pause()
    }
  }, [])

  useEffect(() => {
    if (file instanceof File) {
      getUrl(file).then(url => (audio.current.src = url))
    } else if (typeof file === "string") {
      audio.current.src = file
    }
  }, [file])

  const toggle = useCallback(() => {
    if (audio.current.readyState >= 3) {
      audio.current.paused ? audio.current.play() : audio.current.pause()
    }
  }, [])

  const onLoadedMetadata = useCallback(() => {
    endLoading()
    if (audio.current) {
      if (setAudioDuration) setAudioDuration(audio.current.duration)
      setDuration(getDuration(audio.current.duration))
    }
  }, [])

  const onProgress = useCallback(() => {
    if (audio.current.duration > 0 && audio.current.buffered.length) {
      buffer.current = toPercentage(
        audio.current.buffered.end(audio.current.buffered.length - 1),
        audio.current.duration
      )
    }
  }, [])

  const onTimeUpdate = useCallback((e: any) => {
    window.requestAnimationFrame(() => {
      const value = toPercentage(audio.current.currentTime, audio.current.duration)
      onProgress()

      if (timer.current) {
        timer.current.style.backgroundSize = `${value}%, ${buffer.current}%`
        timer.current.value = String(value)
      }
    })
  }, [])

  const timerChange = useCallback(() => {
    if (audio.current.readyState >= 3) {
      const time = audio.current.duration * (Number(timer.current!.value) / 100)
      audio.current.currentTime = time
    }
  }, [])

  const onMouseDown = () => {
    audio.current.pause()
  }

  const onMouseUP = () => {
    if (audio.current.readyState >= 3) audio.current.play()
  }

  return (
    <Container>
      <PlayButton onClick={toggle}>
        {loading ? <FaPlay /> : isPlaying ? <FaPause /> : <FaPlay />}
      </PlayButton>
      <div>
        <h2 className="title is-5">{filename || "Audio"}</h2>
        <p className="subtitle is-6" style={{ marginBottom: 10 }}>
          Duração: {duration}
        </p>
        <Timeline
          step="0.1"
          defaultValue="0"
          type="range"
          ref={timer}
          onChange={timerChange}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUP}
        />
      </div>
    </Container>
  )
})

const Container = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 100px 1fr;
`

const PlayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  background: white;
  font-size: 30px;
  color: #757575;
  border: none;
  cursor: pointer;
  outline: none;
  transition: all 100ms linear;

  &:active {
    color: #424242;
    transform: scale(0.9);
  }
`

const Timeline = styled.input`
  appearance: none;
  outline: none;
  width: 100%;
  height: 6px;
  align-self: center;
  background-color: #e0e0e0;
  background-image: linear-gradient(#2b87da, #2b87da), linear-gradient(#424242, #424242);
  background-size: 0%;
  background-repeat: no-repeat;
  border-radius: 10px;
  cursor: pointer;
  &::-webkit-slider-runnable-track {
    box-shadow: none;
    border: none;
    background: transparent;
    -webkit-appearance: none;
  }
  &::-moz-range-track {
    box-shadow: none;
    border: none;
    background: transparent;
  }
  &::-moz-focus-outer {
    border: 0;
  }
  &::-webkit-slider-thumb {
    width: 14px;
    height: 14px;
    border: 0;
    background: #fff;
    border-radius: 100%;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.8);
    -webkit-appearance: none;
  }
  &::-moz-range-thumb {
    width: 14px;
    height: 14px;
    border: 0;
    background: #fff;
    border-radius: 100%;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5);
  }
`
