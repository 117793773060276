import React, { useContext } from "react";
import { ResponseWithUser } from "../response.type";
import { toast } from "react-toastify";
import { UserModel } from "../Users/user.types";
import { login, logout } from "./authClient";

interface IContext {
  state: State;
  action: {
    logout(): void;
    setLogin(user: UserModel): void;
    login(email: string, password: string): Promise<void>;
  };
}

interface State {
  user: UserModel | null;
  loading: boolean;
}

export const AuthContext = React.createContext({} as IContext);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export class AuthProvider extends React.PureComponent<{}, State> {
  state = {
    user: null,
    loading: false
  };

  login = async (email: string, password: string): Promise<void> => {
    this.setState({ loading: true }, async () => {
      try {
        const response: ResponseWithUser = await login({ email, password });
        if (response.success) {
          await this.setLoginState(response.data.user);
        } else throw new Error("Falha no login.");
      } catch (err) {
        console.log(err);
        this.setState({ loading: false }, () => {
     
        });
      }
    });
  };

  setLoginState = (user: UserModel) => {
    const state = { loading: false, user } as State;
    return new Promise(resolve => {
      this.setState(state, () => {
        resolve(true);
      });
    });
  };

  logout = () => {
    logout().then(() => {
      this.setState({ user: null });
    });
  };

  render() {
    const value = {
      state: { ...this.state },
      action: {
        login: this.login,
        logout: this.logout,
        setLogin: this.setLoginState
      }
    };

    return <AuthContext.Provider value={value} {...this.props} />;
  }
}
