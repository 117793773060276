import React from "react";
import styled from "@emotion/styled";
import { Practice as IPractice } from "../../../context/Programs/program.type";
import { FaCheck } from "react-icons/fa";
import { Practice } from "../../Practices/practiceList";

interface Props {
  onChange(event: any): void;
  practice: IPractice;
  checked: boolean;
}

export const PracticeInput: React.SFC<Props> = ({
  practice,
  onChange,
  checked
}) => (
  <Wrapper>
    <Input
      hidden
      type="checkbox"
      id={practice._id}
      value={practice._id}
      onChange={onChange}
      checked={checked}
    />

    <CheckBox>
      <FaCheck />
    </CheckBox>

    <label htmlFor={practice._id}>
      <Practice practice={practice} />
    </label>
  </Wrapper>
);

const CheckBox = styled.div`
  flex-shrink: 0;
  width: 23px;
  height: 23px;
  display: inline-block;
  border: 2px solid #9e9e9e;
  text-align: center;
  line-height: 20px;
  margin-right: 12px;
  border-radius: 3px;
  cursor: pointer;

  & svg {
    opacity: 0;
  }
`;

const Input = styled.input`
  &:checked + ${CheckBox} svg {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;

  &:hover > ${CheckBox} {
    background: #d4d4d4;
  }

  & > input {
    display: none;
  }

  & + & {
    border-top: 1px solid rgba(219, 219, 219, 0.5);
    margin-top: 1rem;
    padding-top: 1rem;
  }
`;
