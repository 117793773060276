type method = "GET" | "POST" | "PUT" | "DELETE";

class Client {
  get<T>(endpoint: string, data?: any): Promise<T> {
    return this.request("GET", endpoint, data);
  }

  post<T>(endpoint: string, data?: any): Promise<T> {
    return this.request<T>("POST", endpoint, data);
  }

  put<T>(endpoint: string, data?: any): Promise<T> {
    return this.request<T>("PUT", endpoint, data);
  }

  delete<T>(endpoint: string, data?: any): Promise<T> {
    return this.request<T>("DELETE", endpoint, data);
  }

  async request<T>(method: method = "GET", endpoint: string, body?: any): Promise<T> {
    if (process.env.NODE_ENV === "development") {
      console.log(`${method} url: ${endpoint}`);
    }

    const config = { method } as RequestInit;
    const header = new Headers();

    const token = window.localStorage.getItem("__app_token__");

    if (token) {
      header.append("Authorization", `JWT ${token}`);
    }

    const route = new URL(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}`);

    if (method === "GET" && body) {
      Object.keys(body).forEach(key => {
        const value = body[key];
        if (value) route.searchParams.append(key, value);
      });
    } else if (body && body instanceof FormData) {
      config.body = body;
    } else {
      config["body"] = JSON.stringify(body);
      header.append("Content-Type", "application/json");
    }

    config["headers"] = header;

    const promise: T = await window.fetch(route.href, config).then(res => res.json());
    return promise;
  }
}

export const client = new Client();
