import React from "react";
import styled from "@emotion/styled";

import { RouteComponentProps } from "react-router";
import { FaUpload, FaSave } from "react-icons/fa";
import { useStateForm } from "./state";
import { cx } from "../../utils/classnames";
import { ImageInput } from "./imageInput";
import { CheckBox } from "../../components/CheckBox";
import { TextEditor } from "../../components/Editor";
import { Player } from "../Practice/player";
import { Collapse } from "../../components/Collapse";
import { typeOptions } from "../../context/Programs/utils";
import { Categorys } from "./Categorys";

interface Props extends RouteComponentProps<{ practiceId: string }> {}

export const PracticeEdit: React.SFC<Props> = ({ history, match: { params } }) => {
  const {
    state,
    onChange,
    onChangeAudio,
    setAudioDuration,
    setDescription,
    setCategory,
    updateImage,
    submitForm,
    loading
  } = useStateForm(params.practiceId);

  const submit = () => {
    submitForm(() => {
      history.replace("/practices");
    });
  };

  return (
    <div className="container is-fluid">
      <h2 className="title is-3 has-text-white">{state.title || "Titulo"}</h2>
      <div className="columns">
        <div className="column is-narrow">
          <ImageInput image={state.image} updateCallback={updateImage} />
        </div>
        <div className="column">
          <div className="buttons is-right">
            <button
              className={cx("button is-light", { "is-loading": loading })}
              onClick={submit}
            >
              <span className="icon">
                <FaSave />
              </span>
              <span>Salvar</span>
            </button>
          </div>

          <Container>
            <label className="label">Visibilidade</label>
            <CheckBox
              name="only_program"
              value={state.only_program}
              onChange={onChange}
              options={[
                { label: "Pratica Livre", value: false },
                { label: "Somente Programas", value: true }
              ]}
            />
          </Container>

          <Container>
            <label className="label">Valor</label>
            <CheckBox
              name="type"
              options={typeOptions}
              value={state.type}
              onChange={onChange}
            />
          </Container>

          <Container>
            <label className="label">Categoria</label>
            <Categorys
              selectedId={state.practice_category_id}
              updateCallback={setCategory}
            />
          </Container>

          <Container>
            <label className="label">Autor</label>
            <div className="control">
              <input
                className="input is-fullwidth"
                placeholder="Autor"
                name="author"
                value={state.author}
                onChange={onChange}
              />
            </div>
          </Container>

          <Container>
            <label className="label">Titulo</label>
            <div className="control">
              <input
                className="input is-fullwidth"
                placeholder="Titulo"
                name="title"
                value={state.title}
                onChange={onChange}
              />
            </div>
          </Container>

          <Container>
            <label className="label">Descrição</label>
            <TextEditor
              initialValue={state.description}
              updateCallback={setDescription}
            />
          </Container>

          <Container>
            <label className="label">Audio</label>
            <div className="file" style={{ marginBottom: 16 }}>
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  name="resume"
                  onChange={onChangeAudio}
                />
                <span className="file-cta">
                  <span className="file-icon">
                    <FaUpload />
                  </span>
                  <span className="file-label">Escolha um arquivo de Audio.</span>
                </span>
              </label>
            </div>

            <Collapse visible={state.audio_url !== null}>
              <Player file={state.audio_url} setAudioDuration={setAudioDuration} />
            </Collapse>
          </Container>
        </div>
      </div>
    </div>
  );
};

const Container = styled.div`
  margin-bottom: 2.5rem;
`;

const Table = styled.table`
  & td {
    border: none;
    cursor: pointer;
  }
`;

Table.defaultProps = { className: "table is-hoverable" };
