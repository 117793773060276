import React from "react";
import { Program as IProgram } from "../../../context/Programs/program.type";
import { cx } from "../../../utils/classnames";

export const Program = ({ program }: { program: IProgram }) => {
  const hasImage = "image_url" in program && program.image_url.original;

  return (
    <div className="media">
      <div className="media-left">
        <figure className="image is-64x64">
          {hasImage && (
            <img src={program.image_url.original} alt={program.title} />
          )}
        </figure>
      </div>
      <div className="media-content">
        <div className="content">
          <p>
            <strong>
              {program.title}{" "}
              <span
                className={cx("tag", {
                  "is-link": program.type === "paid",
                  "is-success": program.type === "free"
                })}
              >
                {program.type}
              </span>
            </strong>
            <br />
            {program.description}
          </p>
        </div>
      </div>
    </div>
  );
};
