import { getTime } from "date-fns"

export async function getUrl(url: string | File): Promise<string> {
  if (url instanceof File) {
    return URL.createObjectURL(url)
  }

  let _blob = await fetch(url).then(resp => resp.blob())
  return URL.createObjectURL(_blob)
}

export function getDuration(seconds: number): string {
  const _date = new Date()
  _date.setHours(0)
  _date.setMinutes(0)
  _date.setSeconds(seconds)
  return _date.toTimeString().split(" ")[0]
}
