import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { usePrograms } from "../../context/Programs";
import { useInput, useDebounce, useDebounceValue } from "../../utils/hooks";
import { FaSearch, FaPlus } from "react-icons/fa";
import { PracticesList } from "./practiceList";
import { Loading } from "../../components/Loading";
import { Container } from "../common";

export const Practices = () => {
  const {
    state: { practices, loading }
  } = usePrograms();

  const { value, onChange } = useInput("");

  const data = useDebounceValue(value, practices, 500, (_value, _data) => {
    if (_value.length > 0) {
      const regex = new RegExp(_value, "i");
      return _data.filter(p => regex.test(p.title));
    }
    return _data;
  });

  return (
    <Container>
      <div className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            <div className="field">
              <div className="control has-icons-right">
                <input
                  className="input"
                  type="text"
                  placeholder="buscar"
                  value={value}
                  onChange={onChange}
                />
                <span className="icon is-small is-right">
                  <FaSearch />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="level-right">
          <Link
            to="/practices/add"
            className="button is-link has-text-weight-semibold"
          >
            <span className="icon">
              <FaPlus />
            </span>
            <span>Adicionar</span>
          </Link>
        </div>
      </div>

      <Loading loading={loading} />
      <PracticesList data={data} />
      {!loading && !data.length && (
        <h2 className="title is-5 has-text-grey-light has-text-centered">
          Nenhuma Prática Cadastrada.
        </h2>
      )}
    </Container>
  );
};
