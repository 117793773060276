import React from "react";
import { Practices } from "./practices";

export const Pratices = () => {
  return (
    <div className="container is-fluid">
      <div className="columns is-multiline">
        <div className="column is-12">
          <h1 className="title is-3 has-text-white">Práticas</h1>
          <Practices />
        </div>
      </div>
    </div>
  );
};
