import React from "react"
import ReactDOM from "react-dom"
// import App from "./App"
import { Panel } from "./container/Panel"

import { BrowserRouter, Switch, Route } from "react-router-dom"
import { AuthProvider } from "./context/Auth"
import { Login } from "./view/Login"

import { toast } from "react-toastify"
import * as serviceWorker from "./serviceWorker"
import "react-toastify/dist/ReactToastify.css"
import "./index.css"

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Panel />
      </Switch>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

toast.configure({
  autoClose: 10000,
  draggable: false,
  position: "bottom-right"
})
