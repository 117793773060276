import React, { useMemo, useCallback } from "react";

import styled from "@emotion/styled";
import { RouteComponentProps, Redirect } from "react-router";
import { useUsers } from "../../context/Users";
import { user_sample } from "../../context/Users/sample";
import { cx } from "../../utils/classnames";
import { FaUserEdit } from "react-icons/fa";
import { format } from "date-fns";

interface Props extends RouteComponentProps<{ userId: string }> {}

export const User = ({ match: { params } }: Props) => {
  const {
    state: { users, loading },
    action: { toggleUserAccount }
  } = useUsers();

  const user = useMemo(() => {
    return users.find(u => u._id === params.userId)!;
  }, [params.userId, users]);

  const toggleAccount = useCallback(() => {
    toggleUserAccount(user._id, user.account_type === "paid" ? "free" : "paid");
  }, [user]);

  return user ? (
    <div className="container is-fluid">
      <h1 className="title is-3 has-text-white">{user.display_name || "Teste"}</h1>
      <div className="columns is-multiline">
        <div className="column is-12">
          <p className="subtitle is-5 has-text-white">{user.email}</p>
        </div>

        <div className="column is-12">
          <p className="subtitle is-5 has-text-white">{user.email}</p>
          <Container>
            <Level>
              <div>
                <h1 className="title is-6 has-text-grey">Conta</h1>
                <p className="subtitle is-5">
                  <span
                    className={cx("tag has-text-weight-semibold", {
                      "is-link": user.account_type === "paid",
                      "is-success": user.account_type === "free"
                    })}
                  >
                    {user.account_type === "paid" ? "  Pago" : "  Gratuito"}
                  </span>
                </p>
              </div>
              {user.account_type === "paid" && (
                <div>
                  <h1 className="title is-6 has-text-grey">Status</h1>
                  <p className="subtitle">
                    {user.paid_at && <span>{format(user.paid_at, "D MMM YY HH:mm")}</span>}
                    {!user.paid_at && <span>Liberado pelo Painel</span>}
                  </p>
                </div>
              )}
            </Level>

            <div className="buttons">
              <button
                onClick={toggleAccount}
                className={cx("button is-link has-text-weight-semibold", {
                  "is-loading": loading
                })}
              >
                <span className="icon">
                  <FaUserEdit />
                </span>
                <span>Alterar conta</span>
              </button>
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <Redirect to="/users" />
  );
};

const Container = styled.div`
  margin-bottom: 3.5rem;
`;

const Level = styled.div`
  margin-bottom: 1.5rem;
  display: flex;

  & div {
    margin-right: 1rem;
  }
`;
