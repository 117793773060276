import React, { useState, useContext } from "react";
import { UserModel, Account } from "./user.types";
import { ResponseWithUsers, ResponseWithUserModel } from "../response.type";
import produce from "immer";
import { client } from "../client";
import { toast } from "react-toastify";
import { user_sample } from "./sample";

interface UserContext {
  state: State;
  action: {
    getUser(pageNum: number, pageSize: number): void;
    filterByAccount(account?: Account): void;
    getUserByPage(pageNum: number): void;
    changePageSize(pageSize: number): void;
    searchUser(search: string): void;
    toggleUserAccount(userId: string, account_type: Account): void;
  };
}

export const UserContext = React.createContext({} as UserContext);

export const useUsers = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUsers must be used within a <UserProvider />");
  }
  return context;
};

interface State {
  total: number;
  pageNum: number;
  pageSize: number;
  search: string | undefined;
  account_type?: Account;

  loading: boolean;
  users: UserModel[];
}

export class UserProvider extends React.PureComponent<{}, State> {
  state = {
    //pagination
    total: 0,
    pageNum: 1,
    pageSize: 10,
    search: "",
    account_type: undefined,

    loading: false,
    users: []
  } as State;

  componentDidMount() {
    this.getUser();
  }

  getUser = async (pageNum: number = 1, filters?: any) => {
    const { pageSize, search, account_type } = this.state;

    const config = { pageNum, pageSize, search, account_type, ...filters };

    this.setState({ loading: true }, async () => {
      try {
        const response = await client.get<ResponseWithUsers>("users", config);

        if (response.success) {
          const { users, count } = response.data;

          this.setState({
            ...config,
            total: count,
            loading: false,
            users: users
          });
        } else throw new Error("Falha ao buscar usuário.");
      } catch (err) {
        console.log(err);
        this.setState({ loading: false });
      }
    });
  };

  searchUser = (search: string) => {
    this.getUser(1, { search });
  };

  filterByAccount = (account_type?: Account) => {
    this.getUser(1, { account_type });
  };

  getUserByPage = (pageNum: number) => {
    this.getUser(pageNum);
  };

  changePageSize = (pageSize: number) => {
    this.getUser(this.state.pageNum, { pageSize });
  };

  toggleUserAccount = (userId: string, account_type: Account) => {
    const userIndex = this.state.users.findIndex(u => u._id);

    if (userIndex == -1) {
      return toast.error("Falha ao atualizar status do usuario");
    }

    this.setState({ loading: true }, async () => {
      try {
        const response = await client.put<ResponseWithUserModel>(`users/${userId}/unlockPremium`, {
          account_type
        });
        if (response.success) {
          this.setState(state =>
            produce(state, draft => {
              draft.loading = false;
              draft.users[userIndex] = response.data;
            })
          );
        }
      } catch (err) {
        console.log(err);
        this.setState({ loading: false });
      }
    });
  };

  render() {
    const value = {
      state: { ...this.state },
      action: {
        getUser: this.getUser,
        getUserByPage: this.getUserByPage,
        changePageSize: this.changePageSize,
        filterByAccount: this.filterByAccount,
        toggleUserAccount: this.toggleUserAccount,
        searchUser: this.searchUser
      }
    };

    return <UserContext.Provider value={value} {...this.props} />;
  }
}
