import React from "react";
import styled from "@emotion/styled";
import { Dropdown } from "./dropdown";
import { FaBars } from "react-icons/fa";
import MENU_ICON from "../../assets/images/menu.svg";

// console.log(MENU_ICON);

interface Props {
  toggleSidebar(): void;
}

export const HeaderBar = ({ toggleSidebar }: Props) => (
  <Container className="container is-fluid">
    <Menu onClick={toggleSidebar}>
      <Icon src={MENU_ICON} />
    </Menu>
    <Dropdown />
  </Container>
);

const Container = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8rem;
`;

const Icon = styled.img`
  width: 2rem;
  height: 2rem;
`;

const Menu = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  padding: 0;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  color: white;
`;

export const Button = styled.button`
  background-color: white;
  border-color: transparent;
  outline: none;
  color: black;

  &:focus,
  &:hover {
    border-color: transparent;
    color: black;
  }
`;

Button.defaultProps = { className: "button" };
