import React from "react"
import styled from "@emotion/styled"
import css from "@emotion/css"

interface Props {
  visible: boolean
}

export const Collapse: React.SFC<Props> = React.memo(({ visible, children }) => {
  return <Wrapper visible={visible}>{visible && <Content>{children}</Content>}</Wrapper>
})

const Content = styled.div`
  opacity: 0;
  transition: opacity 200ms ease;
  overflow: hidden;
`

const active = (props: Props) =>
  props.visible &&
  css`
    max-height: 3000px;
    transition: all 0.2s, max-height 4.8s;

    & ${Content} {
      opacity: 1;
    }
  `

const Wrapper = styled.div<Props>`
  background-color: white;
  cursor: pointer;
  margin: 0px;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.4s;
  ${active}
`
