import React from "react"
import styled from "@emotion/styled"
import { Link } from "react-router-dom"
import { usePrograms } from "../../context/Programs"
import { useInput, useDebounceValue } from "../../utils/hooks"
import { FaSearch, FaPlus } from "react-icons/fa"
import { Loading } from "../../components/Loading"
import { Course } from "./course"
import { Container } from "../common"

export const CourseList = () => {
  const {
    state: { courses, loading }
  } = usePrograms()

  const { value, onChange } = useInput("")

  const data = useDebounceValue(value, courses, 500, (_value, _data) => {
    if (_value.length > 0) {
      const regex = new RegExp(_value, "i")
      return _data.filter(p => regex.test(p.title))
    }
    return _data
  })

  return (
    <Container>
      <div className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            <div className="field">
              <div className="control has-icons-right">
                <input
                  className="input"
                  type="text"
                  placeholder="buscar"
                  value={value}
                  onChange={onChange}
                />
                <span className="icon is-small is-right">
                  <FaSearch />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="level-right">
          <Link to="/programs/add" className="button is-link has-text-weight-semibold">
            <span className="icon">
              <FaPlus />
            </span>
            <span>Adicionar</span>
          </Link>
        </div>
      </div>
      <Loading loading={loading} />

      {data.map(course => (
        <StyledLink key={course._id} to={`/programs/view/${course._id}`}>
          <Course course={course} />
        </StyledLink>
      ))}

      {!loading && !data.length && (
        <h2 className="title is-5 has-text-grey-light has-text-centered">
          Nenhum programa cadastrado.
        </h2>
      )}
    </Container>
  )
}

const StyledLink = styled(Link)`
  display: flex;
  padding: 10px;

  &:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
  }
`
