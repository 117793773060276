import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { Pagination } from "../../components/Pagination";
import { useUsers } from "../../context/Users";
import { Search } from "./search";
import { cx } from "../../utils/classnames";
import { withRouter, RouteComponentProps } from "react-router";

interface Props extends RouteComponentProps {}

const _UserTable = ({ history }: Props) => {
  const {
    state: { users, total, pageNum, pageSize },
    action: { getUserByPage, changePageSize }
  } = useUsers();

  const goTo = useCallback((userId: string) => () => history.push(`user/${userId}`), []);

  return (
    <Container>
      <Search />
      {users.length ? (
        <table className="table is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Usuário</th>
              <th className="is-hidden-mobile">Email</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user._id} onClick={goTo(user._id)}>
                <td>
                  <span
                    className={cx("tag has-text-weight-semibold", {
                      "is-link": user.account_type === "paid",
                      "is-success": user.account_type === "free"
                    })}
                  >
                    {user.account_type === "paid" ? "Pago" : "Gratuito"}
                  </span>
                </td>
                <td>
                  <strong>{user.display_name}</strong>
                </td>
                <td className="is-hidden-mobile">{user.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <h2 className="title is-5 has-text-grey-light has-text-centered">Nenhum Usuário Encontrado.</h2>
      )}

      <Pagination
        total={total}
        getPage={getUserByPage}
        currentPage={pageNum}
        itemsPerPage={pageSize}
        changePageSize={changePageSize}
      />
    </Container>
  );
};

export const UserTable = withRouter(_UserTable);

const Container = styled.div`
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
  width: 100%;
`;

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
