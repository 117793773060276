import "numeral/locales/pt-br";
import { FormPractice } from "../../view/PracticeEdit/state";
import { FormProgram } from "../../view/ProgramEdit/state";
import { client } from "../client";
import { IDocument } from "./document.type";
import { ResponseWithDocuments, ResponseWithDocument } from "../response.type";
import { toast } from "react-toastify";
import { FormCourse } from "../../view/CourseEdit/state";

type anyObject = { [key: string]: string };

export const translatePracticeType: anyObject = {
  free: "Gratuito",
  paid: "Pago"
};

export const typeOptions = [
  { label: "Gratuito", value: "free" },
  { label: "Pago", value: "paid" }
];

interface PracticePostData {
  _id: string;
  isNew: boolean;
  image: File | null;
  audio: File | null;
  audio_metadata?: {
    duration: number;
    filename: string;
  };
  data: {
    title: string;
    description: string;
    practice_category_id: string;
    author: string;
    type: any;
  };
}

export function transformPractice(form: FormPractice): PracticePostData {
  const __data = {
    _id: form._id,
    isNew: form.isNew,
    image: null,
    audio: null
  } as any;

  const {
    image,
    audio_url,
    audio_duration,
    audio_filename,
    only_program,
    title,
    description,
    author,
    type,
    practice_category_id
  } = form;

  if (image instanceof File) {
    __data.image = image;
  }

  if (audio_url instanceof File) {
    __data.audio = audio_url;
    __data.audio_metadata = {
      duration: audio_duration,
      filename: audio_filename
    };
  }

  __data.data = {
    title,
    practice_category_id,
    only_program,
    description,
    author,
    type
  };

  return __data;
}

interface CoursePostData {
  _id: string;
  isNew: boolean;
  image?: File | null;
  data: {
    title: string;
    description: string;
    type: any;
  };
}

export function transformCourse(form: FormCourse): CoursePostData {
  const { title, description, type, image } = form;

  const _data = {
    _id: form._id,
    isNew: form.isNew,
    data: {
      type,
      title,
      description
    }
  } as CoursePostData;

  if (image instanceof File) {
    _data.image = image;
  }

  return _data;
}

interface ProgramPostData {
  _id: string;
  courseId: string;
  isNew: boolean;
  documents: IDocument[];
  image?: File | null;
  data: {
    title: string;
    description: string;
    practice_ids: string[];
    type: any;
  };
}

export function transformProgram(form: FormProgram): ProgramPostData {
  const { title, description, type, practice_ids, image } = form;

  const _data = {
    _id: form._id,
    courseId: form.courseId,
    isNew: form.isNew,
    documents: form.documents,
    data: {
      type,
      title,
      description,
      practice_ids
    }
  } as ProgramPostData;

  if (image instanceof File) {
    _data.image = image;
  }

  return _data;
}

export function uploadFile(
  file: File,
  options: {
    name: string;
    url: string;
    metadata?: any;
  }
): Promise<any> {
  const formData = new FormData();

  const { url, name, metadata } = options;
  formData.append(name, file, file.name);

  if (options.metadata) {
    Object.keys(metadata).forEach(key => {
      formData.append(key, metadata[key]);
    });
  }

  return client.post(url, formData);
}

export async function saveDocuments(documents: IDocument[], programId: string) {
  const _documents = documents.filter(doc => doc.updated);

  _documents.forEach(async document => {
    const { file, ...data } = document;

    const _data = {
      ...data,
      program_id: programId
    };

    const response = document.isNew
      ? await client.post<ResponseWithDocument>("documents", _data)
      : await client.put<ResponseWithDocument>(`documents/${document._id}`, _data);

    if (response.success) {
      const { _id } = response.data;

      if (file instanceof File) {
        uploadFile(file, {
          name: "newDocumentFile",
          url: `documents/${_id}/file`
        });
      }
    }
  });
}

export async function deleteDocument(
  id: string,
  callbackSuccess: () => void,
  callbackFail?: () => void
) {
  try {
    const response = await client.delete<ResponseWithDocument>(`documents/${id}`);
    if (response.success) {
      callbackSuccess && callbackSuccess();
    } else {
      callbackFail && callbackFail();
      toast.error("Não foi possivel excluir esta apostila.");
    }
  } catch (err) {
    console.log(err);
  }
}

export async function getDocuments(id?: string): Promise<IDocument[]> {
  if (!id) return [];

  const response = await client.get<ResponseWithDocuments>(`programs/${id}/documents`);

  if (response.success) {
    return response.data.map(
      (document): IDocument => {
        const { doc_url, ...doc } = document;
        return {
          ...doc,
          file: doc_url || undefined,
          isNew: false,
          updated: false
        };
      }
    );
  }

  return [];
}
