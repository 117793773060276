import React, { useState, useCallback } from "react";
import styled from "@emotion/styled";
import produce from "immer";
import { IDocument } from "../../context/Programs/document.type";
import { FaPlus, FaUser, FaUpload, FaTrash, FaHeading } from "react-icons/fa";
import { deleteDocument } from "../../context/Programs/utils";
import { useDebounceCallback } from "../../utils/hooks";

interface Props {
  docs: IDocument[];
  updateCallback: (docs: IDocument[]) => void;
}

type InputChange = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>;

const newDocument = (): IDocument => ({
  _id: new Date().toString(),
  isNew: true,
  title: "",
  description: "",
  author: "",
  disabled: false
});

export const Documents: React.SFC<Props> = React.memo(({ docs, updateCallback }) => {
  const [documents, setDocuments] = useState<IDocument[]>(() => {
    return docs.length > 0 ? docs : [];
  });

  useDebounceCallback(documents, 800, docs => {
    updateCallback(docs);
  });

  const addDocument = useCallback(() => {
    setDocuments(s => [...s, newDocument()]);
  }, []);

  const changeFile = useCallback(
    (index: number) => (e: InputChange) => {
      const files = e.target.files as FileList;
      const [document, ...rest] = Array.from(files);
      setDocuments(s =>
        produce(s, draft => {
          draft[index].file = document;
          draft[index].updated = true;
        })
      );
    },
    []
  );

  const handleInputChange = useCallback(
    (index: number) => (e: InputChange) => {
      const { value, name } = e.target;
      setDocuments(s =>
        produce(s, draft => {
          draft[index][name] = value;
          draft[index].updated = true;
        })
      );
    },
    []
  );

  const removeDocument = useCallback(
    (id: string, index: number) => () => {
      const document = documents[index];
      if (document.isNew) {
        setDocuments(prev => prev.filter(doc => doc._id !== id));
      } else {
        setDocuments(prev =>
          produce(prev, draft => {
            draft[index].disabled = true;
          })
        );
        deleteDocument(
          id,
          () => setDocuments(prev => prev.filter(doc => doc._id !== id)),
          () =>
            setDocuments(prev =>
              produce(prev, draft => {
                draft[index].disabled = false;
              })
            )
        );
      }
    },
    [documents]
  );

  return (
    <React.Fragment>
      <Container>
        {documents.map((doc, idx) => (
          <div key={doc._id} className="columns is-multiline">
            <div className="column is-4">
              <p className="control is-expanded has-icons-left">
                <input
                  type="text"
                  className="input"
                  placeholder="Autor"
                  name="author"
                  value={doc.author}
                  onChange={handleInputChange(idx)}
                />
                <span className="icon is-small is-left">
                  <FaUser />
                </span>
              </p>
            </div>
            <div className="column is-8">
              <p className="control is-expanded has-icons-left">
                <input
                  type="text"
                  className="input"
                  placeholder="Titulo"
                  name="title"
                  value={doc.title}
                  onChange={handleInputChange(idx)}
                />
                <span className="icon is-small is-left">
                  <FaHeading />
                </span>
              </p>
            </div>
            <div className="column is-12">
              <div className="field">
                <div className="control">
                  <textarea
                    rows={2}
                    className="textarea"
                    name="description"
                    value={doc.description}
                    placeholder="Insira uma breve descrição sobre a apostila"
                    onChange={handleInputChange(idx)}
                  />
                </div>
              </div>
            </div>
            <div className="column is-12">
              <div className="file has-name is-fullwidth">
                <label className="file-label">
                  <input
                    type="file"
                    name="document"
                    className="file-input"
                    accept="application/pdf"
                    onChange={changeFile(idx)}
                  />
                  <span className="file-cta">
                    <span className="file-icon">
                      <FaUpload />
                    </span>
                    <span className="file-label">Selecionar arquivo (.pdf)</span>
                  </span>
                  <span className="file-name has-background-white">
                    <Filename file={doc.file} />
                  </span>
                </label>
              </div>
            </div>
            <div className="column is-12">
              <button className="button is-small has-text-weight-semibold" onClick={removeDocument(doc._id, idx)}>
                <span className="icon">
                  <FaTrash />
                </span>
                <span>Remover</span>
              </button>
            </div>
          </div>
        ))}
        {!documents.length && (
          <article className="message is-warning">
            <div className="message-body">
              <strong>Nenhuma apostila</strong>
            </div>
          </article>
        )}
      </Container>

      <div className="buttons is-right">
        <button className="button is-link has-text-weight-semibold" onClick={addDocument}>
          <span className="icon">
            <FaPlus />
          </span>
          <span>Adicionar</span>
        </button>
      </div>
    </React.Fragment>
  );
});

const Filename = React.memo(({ file }: { file?: File | string }) => {
  if (typeof file === "string") {
    return (
      <a href={file} target="_blank" rel="noopener noreferrer">
        Visualizar aquivo
      </a>
    );
  } else if (file instanceof File) {
    return <span>{file.name}</span>;
  } else {
    return <span>Nenhum arquivo selecionado</span>;
  }
});

const Container = styled.div`
  padding: 12px 0;
  margin: -1px 0 1.5rem;

  & > div {
    padding-bottom: 1.5rem;
  }
`;
