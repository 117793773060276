import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { NavLink } from "react-router-dom";
import LOGO from "../../assets/images/logo-new.png";
import {
  FaChartArea,
  FaCalendarAlt,
  FaUserFriends,
  FaLayerGroup
} from "react-icons/fa";

interface Props {
  open: boolean;
  toggle(): void;
}

export const Sidebar = ({ open, toggle }: Props) => (
  <React.Fragment>
    <Wrapper open={open}>
      <Container>
        <LogoContainer>
          <img src={LOGO} alt="Logo" />
        </LogoContainer>
        <aside className="menu section">
          <ul className="menu-list">
            {/* <Item disabled>
              <NavLink exact to="/" activeClassName="is-active">
                <span className="icon">
                  <FaChartArea />
                </span>
                <span className="has-text-weight-semibold">Dashboard</span>
              </NavLink>
            </Item> */}
            <Item>
              <NavLink exact to="/programs" activeClassName="is-active">
                <span className="icon">
                  <FaCalendarAlt />
                </span>
                <span className="has-text-weight-semibold">Programas</span>
              </NavLink>
            </Item>
            <Item>
              <NavLink exact to="/practices" activeClassName="is-active">
                <span className="icon">
                  <FaUserFriends />
                </span>
                <span className="has-text-weight-semibold">Práticas</span>
              </NavLink>
            </Item>
            <Item>
              <NavLink exact to="/categories" activeClassName="is-active">
                <span className="icon">
                  <FaLayerGroup />
                </span>
                <span className="has-text-weight-semibold">Categoria</span>
              </NavLink>
            </Item>
            <Item>
              <NavLink exact to="/users" activeClassName="is-active">
                <span className="icon">
                  <FaUserFriends />
                </span>
                <span className="has-text-weight-semibold">Usuários</span>
              </NavLink>
            </Item>
          </ul>
        </aside>
      </Container>
    </Wrapper>
  </React.Fragment>
);

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IContainer {
  open: boolean;
}

const hideContainer = (props: IContainer) =>
  props.open &&
  css`
    overflow: hidden;
    opacity: 0;
    width: 0;
  `;

const Wrapper = styled.div<IContainer>`
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.14);
  transition: all 400ms ease;
  width: 210px;
  z-index: 100;
  ${hideContainer}
`;

const Container = styled.div`
  position: sticky;
  top: 0;
`;

interface Item {
  disabled?: boolean;
}

const ItemProps = (props: Item) =>
  props.disabled &&
  css`
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  `;

const Item = styled.li<Item>`
  ${ItemProps}
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 90;
  background: rgba(0, 0, 0, 0.1);
`;
