import React, { useState } from "react";
import styled from "@emotion/styled";

import { RouteComponentProps } from "react-router";
import { usePrograms } from "../../context/Programs";
import { FaPen, FaTrash, FaDownload } from "react-icons/fa";
import { IProgram } from "../../context/Programs/program.type";
import { useCancelablePromise } from "../../utils/hooks";
import { Link } from "react-router-dom";
import { cx } from "../../utils/classnames";
import { PracticesList } from "../Practices/practiceList";
import { getDocuments } from "../../context/Programs/utils";
import { IDocument } from "../../context/Programs/document.type";
import { Box, ImageContainer } from "../Practice";

interface Props extends RouteComponentProps<{ courseId: string, programId: string }> { }

export const Program: React.SFC<Props> = ({ history, match: { params } }) => {
  const {
    state: { loading },
    action: { getProgram, deleteProgram }
  } = usePrograms();

  const [program, setProgram] = useState<IProgram | null>(null);
  const [documents, setDocuments] = useState<IDocument[]>([]);

  useCancelablePromise(() => getDocuments(params.programId), setDocuments);

  useCancelablePromise(() => getProgram(params.programId), setProgram, () =>
    history.goBack()
  );

  const _deleteProgram = () => {
    if (program) {
      deleteProgram(program._id, () => {
        history.goBack()
      })
    }
  }

  const hasImage = program && program.image_url && program.image_url.original;

  return program ? (
    <div className="container is-fluid">
      <h2 className="title is-3 has-text-white">{program.title}</h2>
      <div className="columns">
        <div className="column is-narrow">
          <Box>
            <ImageContainer>
              {hasImage && <img src={program.image_url.original} alt="ahhh" />}
            </ImageContainer>
          </Box>
        </div>
        <div className="column">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <span
                  className={cx("tag is-medium", {
                    "is-link": program.type === "paid",
                    "is-success": program.type === "free"
                  })}
                >
                  {program.type}
                </span>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <Link
                  to={{
                    pathname: `/programs/${params.courseId}/section/edit/${program._id}`,
                    state: { program }
                  }}
                  className="button is-light"
                >
                  <span className="icon">
                    <FaPen />
                  </span>
                  <span>Editar</span>
                </Link>
              </div>
              <div className="level-item">
                <button
                  className="button has-text-weight-semibold"
                  disabled={loading}
                  onClick={_deleteProgram}
                >
                  <span className="icon">
                    <FaTrash />
                  </span>
                  <span>Excluir</span>
                </button>
              </div>
            </div>
          </div>
          <Container>
            <h1 className="title is-6">Descrição</h1>
            <p className="subtitle">{program.description}</p>
          </Container>

          <Container>
            <h1 className="title is-6">Práticas</h1>
            <PracticesList data={program.practices} />
          </Container>

          <Container>
            <h1 className="title is-6">Apostilas</h1>

            {documents.map(doc => (
              <div className="media" key={doc._id}>
                {doc.file && (
                  <div className="media-left">
                    <a
                      href={doc.file}
                      className="button is-light"
                      target="_blanck"
                      rel="noopener noreferrer"
                    >
                      <span className="icon">
                        <FaDownload />
                      </span>
                      <span>Visualizar</span>
                    </a>
                  </div>
                )}
                <div className="media-content">
                  <div className="content">
                    <p>
                      <strong>{doc.title}</strong> <small>{doc.author}</small>
                      <br />
                      {doc.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Container>
        </div>
      </div>
    </div>
  ) : null;
};

const Container = styled.div`
  margin-bottom: 3.5rem;
`;

const Table = styled.table`
  & td {
    border: none;
    cursor: pointer;
  }
`;

Table.defaultProps = { className: "table is-hoverable" };
