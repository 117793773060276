import { useAuth } from "."
import { getUser, getToken } from "./authClient"

export const useLoginState = (): boolean => {
  const token = getToken()

  const {
    state: { user },
    action: { setLogin, logout }
  } = useAuth()

  // if (process.env.NODE_ENV === "development") return true
  if (user) return true
  else if (!token) return false

  const promise = getUser()

  promise.then(result => {
    if (result && result.success) setLogin(result.data)
    else logout()
  })

  throw promise
}
