import React from "react";
import styled from "@emotion/styled";
import { RouteComponentProps } from "react-router";
import { FaSave, FaPlus } from "react-icons/fa";
import { cx } from "../../utils/classnames";
import { useStateForm } from "./state";
import { Practice } from "../Practices/practiceList";
import { TextEditor } from "../../components/Editor";
import { CheckBox } from "../../components/CheckBox";
import { typeOptions } from "../../context/Programs/utils";
import { ImageInput } from "../PracticeEdit/imageInput";
//@ts-ignore
import ReactDragListView from "react-drag-listview/lib/index.js";

interface Props extends RouteComponentProps<{ courseId: string }> { }

export const CourseEdit: React.SFC<Props> = ({
  history,
  match: { params }
}) => {
  const {
    state,
    loading,
    onChange,
    submitForm,
    updateImage,
    setDescription
  } = useStateForm(params.courseId);

  const submit = () => {
    submitForm(() => {
      history.goBack();
    });
  };

  return (
    <React.Fragment>
      <div className="container is-fluid">
        <h2 className="title is-3 has-text-white">{state.title || "Titulo"}</h2>
        <div className="columns">
          <div className="column is-narrow">
            <ImageInput image={state.image} updateCallback={updateImage} />
          </div>

          <div className="column">
            <div className="buttons is-right">
              <button
                className={cx("button is-light", { "is-loading": loading })}
                onClick={submit}
              >
                <span className="icon">
                  <FaSave />
                </span>
                <span>Salvar</span>
              </button>
            </div>

            <Container>
              <label className="label">Tipo</label>
              <CheckBox
                name="type"
                options={typeOptions}
                value={state.type}
                onChange={onChange}
              />
            </Container>

            <Container>
              <label className="label">Titulo</label>
              <div className="control">
                <input
                  name="title"
                  className="input is-fullwidth"
                  placeholder="Titulo"
                  onChange={onChange}
                  value={state.title}
                />
              </div>
            </Container>

            <Container>
              <label className="label">Descrição</label>
              <TextEditor
                initialValue={state.description}
                updateCallback={setDescription}
              />
            </Container>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const Container = styled.div`
  margin-bottom: 3rem;
`;

const Table = styled.table`
  & td {
    border: none;
    cursor: pointer;
  }
`;

Table.defaultProps = { className: "table is-hoverable" };
