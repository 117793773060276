import { useState, useCallback } from "react";
import { Practice, PracticeType } from "../../context/Programs/program.type";
import { usePrograms } from "../../context/Programs";
import { useCancelablePromise } from "../../utils/hooks";
import { toast } from "react-toastify";

export interface FormPractice {
  _id: string;
  title: string;
  practice_category_id: string;
  author: string;
  type: PracticeType;
  only_program: boolean;
  description: string;
  audio_url: File | string | null;
  audio_duration: number;
  audio_filename: string;
  image: File | string | null;
  isNew: boolean;
}

const initialState: FormPractice = {
  _id: "",
  title: "",
  type: "free",
  author: "",
  description: "",
  only_program: false,
  practice_category_id: "",
  audio_url: null,
  audio_duration: 0,
  audio_filename: "",
  image: null,
  isNew: true
};

export const useStateForm = (id?: string) => {
  const [state, setState] = useState<FormPractice>(initialState);

  const {
    state: { saving },
    action: { getPractice, savePractice }
  } = usePrograms();

  useCancelablePromise(() => getPractice(id), data => setState(getInitialState(data)));

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value, name } = e.target;
      setState(prev => {
        let _value = value;

        if (name === "only_program") {
          _value = JSON.parse(value);
        }

        return { ...prev, [name]: _value };
      });
    },
    []
  );

  const setDescription = useCallback((description: string) => {
    setState(prev => ({ ...prev, description }));
  }, []);

  const updateImage = useCallback((image: File) => {
    setState(prev => ({ ...prev, image }));
  }, []);

  const setCategory = useCallback((id: string) => {
    setState(prev => ({ ...prev, practice_category_id: id }));
  }, []);

  const setAudioDuration = useCallback((duration: number) => {
    setState(prev => ({ ...prev, audio_duration: duration }));
  }, []);

  const onChangeAudio = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = Array.from(e.target.files || []).find(
      f => f.type.startsWith("audio/") || f.type.startsWith("video/")
    );
    if (file) {
      setState(prev => ({ ...prev, audio_url: file }));
    }
  }, []);

  const submitForm = useCallback(
    (cb: () => void) => {
      validation(state)
        .then(state => savePractice(state, cb))
        .catch(msg => toast.info(msg));
    },
    [state]
  );

  return {
    state,
    onChange,
    onChangeAudio,
    setAudioDuration,
    setDescription,
    setCategory,
    updateImage,
    submitForm,
    loading: saving
  };
};

function validation(state: FormPractice) {
  let message: any = null;

  if (state.title.length <= 2) {
    message = "Insira um titulo para esta Prática";
  } else if (state.description.length <= 2) {
    message = "Insira uma descrição para esta Prática";
  }

  if (message) {
    return Promise.reject(message);
  }

  return Promise.resolve(state);
}

function getInitialState(state: Practice): FormPractice {
  const {
    image_url,
    audio,
    description,
    title,
    type,
    author,
    practice_category_id,
    only_program
  } = state;

  const hasImage = "image_url" in state && image_url.original;
  const hasAudio = "audio" in state && state.audio.url;

  const _state = {
    ...initialState,
    ...(state._id && { _id: state._id }),
    ...(hasImage && { image: image_url.original }),
    ...(practice_category_id && { practice_category_id }),
    ...(typeof only_program === "boolean" && { only_program }),
    ...(hasAudio && { audio_url: audio.url, audio_filename: audio.filename }),
    ...(description && { description }),
    ...(author && { author }),
    ...(title && { title }),
    ...(type && { type }),
    isNew: false
  } as FormPractice;

  return _state;
}
