import React, { Suspense } from "react";
import styled from "@emotion/styled";
import { Sidebar } from "../../components/Sidebar";
import { HeaderBar } from "../../components/HeaderBar";
import { useToggle, useMedia } from "../../utils/hooks";
import { Switch, Route, Redirect } from "react-router";
import { useLoginState } from "../../context/Auth/loginState";
import { PlaceProvider } from "../../context/Programs";
import { Pratices } from "../../view/Practices";
import { Fallback } from "./fallback";
import { Practice } from "../../view/Practice";
import { PanelUsers } from "../../view/Users";
import { User } from "../../view/User";
import { PracticeEdit } from "../../view/PracticeEdit";
import { Program } from "../../view/Program";
import { ProgramEdit } from "../../view/ProgramEdit";
import { Courses } from "../../view/Courses";
// import { Program } from "../../view/Program";
// import { ProgramEdit } from "../../view/ProgramEdit";
import { Categories } from "../../view/Categories";
import { UserProvider } from "../../context/Users";
import { Course } from "../../view/Course";
import { CourseEdit } from "../../view/CourseEdit";

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  min-height: 100vh;
`;

const Content = styled.div`
  padding: 1rem;
  overflow: auto;
  transition: all 400ms linear;
  background: linear-gradient(to bottom, #143e51, #143e51 172px, #fff 173px, #fff 100%);
`;

const InnerPanel: React.FC = () => {
  const isLogin = useLoginState();
  const { value: showSidebar, toggle } = useMedia(768);

  return isLogin ? (
    <Container>
      <Sidebar open={showSidebar} toggle={toggle} />
      <Content>
        <HeaderBar toggleSidebar={toggle} />
        <Switch>
          <PlaceProvider>
            <Route exact path="/" component={Courses} />
            <Route exact path="/programs" component={Courses} />
            <Route exact path="/practices" component={Pratices} />
            <Route exact path="/categories" component={Categories} />

            <Route exact path="/practices/add" component={PracticeEdit} />
            <Route exact path="/programs/add" component={CourseEdit} />
            <Route exact path="/programs/:courseId/section/add" component={ProgramEdit} />

            <Route path="/practices/view/:practiceId" component={Practice} />
            <Route path="/programs/view/:courseId" component={Course} />
            <Route path="/programs/:courseId/section/view/:programId" component={Program} />

            <Route path="/practices/edit/:practiceId" component={PracticeEdit} />
            <Route path="/programs/edit/:courseId" component={CourseEdit} />
            <Route path="/programs/:courseId/section/edit/:programId" component={ProgramEdit} />

            <UserProvider>
              <Route exact path="/users" component={PanelUsers} />
              <Route exact path="/user/:userId" component={User} />
            </UserProvider>
          </PlaceProvider>
        </Switch>
      </Content>
    </Container>
  ) : (
    <Redirect to="/login" />
  );
};

export const Panel = ({ ...props }) => {
  return (
    <Suspense fallback={<Fallback />}>
      <Route {...props} component={InnerPanel} />
    </Suspense>
  );
};
