import React, { useRef, useEffect, useContext } from "react"
import { FaChevronDown, FaSignOutAlt, FaUser } from "react-icons/fa"
import { useToggle } from "../../utils/hooks"
import { NavLink } from "react-router-dom"
import { cx } from "../../utils/classnames"
import { AuthContext } from "../../context/Auth"
import { Button } from "."

export const Dropdown = React.memo(() => {
  const {
    state: { user },
    action: { logout }
  } = useContext(AuthContext)

  const toggle = useToggle(false)
  const close = useRef(() => toggle.disable())
  const open = (event: React.MouseEvent<MouseEvent>) => {
    event.stopPropagation()
    toggle.activate()
  }

  useEffect(() => {
    if (toggle.active) document.addEventListener("click", close.current)
    return () => document.removeEventListener("click", close.current)
  }, [open])

  return (
    <div
      className={cx("dropdown is-right", { "is-active": toggle.active })}
      onClick={toggle.toggle}
    >
      <div className="dropdown-trigger">
        <Button>
          <span className="has-text-weight-semibold">Admin</span>
          <span className="icon is-small">
            <FaChevronDown />
          </span>
        </Button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          <NavLink to="/profile" className="dropdown-item">
            <span className="icon">
              <FaUser />
            </span>
            <span>Perfil</span>
          </NavLink>
          <div className="dropdown-item" onClick={logout}>
            <span className="icon">
              <FaSignOutAlt />
            </span>
            <span>Logout</span>
          </div>
        </div>
      </div>
    </div>
  )
})
