import React from "react"
import LOADING from "../../assets/images/loading.svg"

export const Loading = ({ loading }: { loading?: boolean }) => {
  if (loading || loading === undefined) {
    return (
      <div style={{ display: "flex" }}>
        <img src={LOADING} alt="Carregando..." style={{ margin: "auto" }} />
      </div>
    )
  }

  return null
}
