import React, { useEffect, useState, useRef, useLayoutEffect } from "react";

import showdown from "showdown";
//@ts-ignore
import { init } from "pell";
import { useDebounceCallback } from "../../utils/hooks";
import styled from "@emotion/styled";

const Converter = new showdown.Converter({
  openLinksInNewWindow: true,
  simpleLineBreaks: true,
  emoji: true
});

const pellActions = [
  "bold",
  "italic",
  "underline",
  "strikethrough",
  "heading1",
  "heading2",
  "paragraph",
  "quote",
  "olist",
  "ulist",
  "link"
];

interface Props {
  initialValue: string;
  updateCallback(text: string): void;
}

export const TextEditor: React.SFC<Props> = ({
  initialValue,
  updateCallback
}) => {
  const container = useRef<HTMLDivElement>(null);
  const editor = useRef<any>(null);

  const [state, setState] = useState<string>("");

  const shouldUpdate = useRef(true);

  useLayoutEffect(() => {
    if (shouldUpdate.current && Boolean(initialValue)) {
      shouldUpdate.current = false;
      const textString = Converter.makeHtml(initialValue);
      setTimeout(() => {
        if (editor.current) editor.current.content.innerHTML = textString;
      }, 0);
    }
  }, [initialValue]);

  useLayoutEffect(() => {
    if (container.current) {
      editor.current = init({
        element: container.current,
        actions: pellActions,
        defaultParagraphSeparator: "p",
        onChange: (htmlText: string) => {
          setState(htmlText);
          shouldUpdate.current = false;
        },
        classes: {
          content: "editor-content content"
        }
      });
    }
  }, []);

  useDebounceCallback(state, 500, text => {
    const _markdown = Converter.makeMarkdown(text);
    // fix dont ask why
    const cleaned = _markdown.replace(/<.*>/g, "");
    updateCallback(cleaned);
  });

  return <Editor ref={container} />;
};

const Editor = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  color: #4a4a4a;
  display: block;
  padding: 0.7rem;
`;
