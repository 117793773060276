import React from "react";
import styled from "@emotion/styled";
import css from "@emotion/css";

import { Preview } from "./preview";

interface Props {
  image: File | string | null;
  updateCallback(i: File): void;
  removeImage?(i: string): void;
}

interface State {
  image: File | string | null;
  dragover: boolean;
}

export class ImageInput extends React.PureComponent<Props, State> {
  state: State = {
    image: this.props.image,
    dragover: false
  };

  updateImage = (files: FileList | null): void => {
    if (files && files.length > 0) {
      const [file, ..._] = Array.from(files);

      this.setState({ image: file, dragover: false }, () => {
        if (this.state.image instanceof File) {
          this.props.updateCallback(this.state.image);
        }
      });
    }
  };

  handleChange = (e: React.ChangeEvent<any>): void => {
    const { files } = e.target;
    this.updateImage(files);
  };

  handleDragOver = (e: React.DragEvent<any>): void => {
    e.stopPropagation();
    e.preventDefault();
    if (e.dataTransfer) {
      e.dataTransfer.dropEffect = "copy";
      this.setState({
        dragover: true
      });
    }
  };

  handleDragLeave = (e: React.DragEvent<any>): void => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      dragover: false
    });
  };

  handleDragDrop = (e: React.DragEvent<any>): void => {
    e.stopPropagation();
    e.preventDefault();

    const files = e.dataTransfer && e.dataTransfer.files;
    this.updateImage(files);
  };

  render() {
    const { dragover, image } = this.state;

    return (
      <Wrapper>
        <Container
          dragover={dragover}
          onDragOver={this.handleDragOver}
          onDragLeave={this.handleDragLeave}
          onDragEnd={this.handleDragLeave}
          onDrop={this.handleDragDrop}
        >
          <Preview image={this.props.image} />
          <input hidden type="file" accept="image/*" onChange={this.handleChange} />
        </Container>
      </Wrapper>
    );
  }
}

interface IContainer {
  dragover: boolean;
}

const dragOver = (props: IContainer) =>
  props.dragover &&
  css`
    opacity: 0.5;
  `;

const Container = styled.label<IContainer>`
  cursor: pointer;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  color: #4a4a4a;
  display: block;
  padding: 0.65rem;
  width: 100%;

  ${dragOver}
`;

const Wrapper = styled.div`
  position: relative;
`;
