import { ResponseWithUser, ResponseWithUserModel } from "../response.type";
import { client } from "../client";

const localStorageKey = "__app_token__";

function handleUserResponse<T extends ResponseWithUser>(response: T): T {
  if (response.success) {
    window.localStorage.setItem(localStorageKey, response.data.token);
  }

  return response;
}

export function login(form: any) {
  return client.post<ResponseWithUser>("user/admin/signin", form).then(handleUserResponse);
}

export function logout() {
  window.localStorage.removeItem(localStorageKey);
  return Promise.resolve(null);
}

export function getToken() {
  return window.localStorage.getItem(localStorageKey);
}

export function getUser(): Promise<null | ResponseWithUserModel> {
  const token = getToken();

  if (!token) {
    return Promise.resolve(null);
  }

  return client.get<ResponseWithUserModel>("users/me").catch(err => {
    return logout();
  });
}
