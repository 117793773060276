import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { cx } from "../../utils/classnames";
import {
  Practice as IPractice,
  PracticeType as IPracticeType
} from "../../context/Programs/program.type";
import { translatePracticeType } from "../../context/Programs/utils";
import { FaBars } from "react-icons/fa";

interface Props {
  data: IPractice[];
}

export const PracticesList = React.memo(({ data }: Props) => {
  return (
    <React.Fragment>
      {data.map(practice => (
        <StyledLink key={practice._id} to={`/practices/view/${practice._id}`}>
          <Practice practice={practice} />
        </StyledLink>
      ))}
    </React.Fragment>
  );
});

interface PracticeProps {
  practice: IPractice;
  sortable?: boolean;
}

export const Practice: React.SFC<PracticeProps> = ({ practice, children, sortable }) => {
  const hasImage = "image_url" in practice && practice.image_url.original;

  return (
    <div className="media" style={{
      padding: "10px 0",
      margin: "10px 0"
    }}>
      {
        sortable &&
        <a href="#" style={{ margin: "0 5px 0 0", cursor: "grab" }}>
          <FaBars size={30} color="black" />
        </a>
      }
      <div className="media-left">
        <figure className="image is-64x64">
          {hasImage && <img src={practice.image_url.original} alt={practice.title} />}
        </figure>
      </div>
      <div className="media-content">
        <div className="content">
          <p>
            <strong>{practice.title} </strong>
            <br />
            <span className="is-hidden-mobile is-block">
              <strong>Autor: </strong>
              {practice.author}
            </span>
            <PracticeType type={practice.type} />
            <PracticeVibility only_program={practice.only_program} />
          </p>
        </div>
      </div>
      {children}
    </div>
  );
};

export const PracticeType = ({ type }: { type: IPracticeType }) => (
  <span
    style={{ marginRight: 10 }}
    className={cx("tag is-small has-text-weight-semibold", {
      "is-link": type === "paid",
      "is-success": type === "free"
    })}
  >
    {translatePracticeType[type]}
  </span>
);

export const PracticeVibility: React.SFC<{ only_program: boolean }> = ({
  only_program
}) => (
  <span
    style={{ marginRight: 10 }}
    className={cx("tag is-small is-dark has-text-weight-semibold", {
      "is-dark": only_program,
      "is-primary": !only_program
    })}
  >
    {only_program ? "Somente Programas" : "Prática Livre"}
  </span>
);

const StyledLink = styled(Link)`
  display: flex;
  padding: 10px;

  &:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
  }
`;
