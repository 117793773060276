import React from "react";
import { CourseList } from "./coursesList";

export const Courses = () => {
  return (
    <div className="container is-fluid">
      <div className="columns is-multiline">
        <div className="column is-12">
          <h1 className="title is-3 has-text-white">Programas</h1>
          <CourseList />
        </div>
      </div>
    </div>
  );
};
