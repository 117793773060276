import { useState, useCallback, useEffect } from "react";
import {
  Practice,
  PracticeType,
  IProgram
} from "../../context/Programs/program.type";
import { usePrograms } from "../../context/Programs";
import { useCancelablePromise, useToggle } from "../../utils/hooks";
import { toast } from "react-toastify";
import { IDocument } from "../../context/Programs/document.type";
import { getDocuments } from "../../context/Programs/utils";

export interface FormProgram {
  _id: string;
  courseId: string;
  title: string;
  type: PracticeType;
  description: string;
  practice_ids: string[];
  practices: Practice[];
  documents: IDocument[];
  image: File | string | null;
  modalOpen: boolean;
  showDocuments: boolean;
  isNew: boolean;
}

const initialState: FormProgram = {
  _id: "",
  courseId: "",
  title: "",
  type: "free",
  description: "",
  practice_ids: [],
  practices: [],
  documents: [],
  image: null,
  modalOpen: false,
  showDocuments: false,
  isNew: true
};

export const useStateForm = (courseId: string, id?: string) => {
  const [state, setState] = useState<FormProgram>({ ...initialState, courseId });

  const {
    state: { saving },
    action: { getProgram, saveProgram, getPraticesById }
  } = usePrograms();

  useCancelablePromise(
    () => getProgram(id),
    data => setState(getInitialState(data))
  );

  useCancelablePromise(
    () => getDocuments(id),
    documents => {
      setState(prev => ({ ...prev, documents, showDocuments: true }));
    }
  );

  const { activate, disable, active } = useToggle(false);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value, name } = e.target;
      setState(prev => ({ ...prev, [name]: value }));
    },
    []
  );

  const setDescription = useCallback((description: string) => {
    setState(prev => {
      return { ...prev, description };
    });
  }, []);

  const updateImage = useCallback((image: File) => {
    setState(prev => ({ ...prev, image }));
  }, []);

  const setDocuments = useCallback((docs: IDocument[]) => {
    setState(prev => ({ ...prev, documents: docs }));
  }, []);

  const updatePractices = useCallback((practice_ids: string[]) => {
    setState(prev => {
      const practices = getPraticesById(practice_ids);

      return {
        ...prev,
        practice_ids,
        practices
      };
    });
  }, []);

  const removePractice = useCallback(
    (id: string) => () => {
      setState(prev => {
        const practice_ids = prev.practice_ids.filter(p => p !== id);
        const practices = prev.practices!.filter(p => p._id !== id);

        return {
          ...prev,
          practices,
          practice_ids
        };
      });
    },
    []
  );

  const submitForm = useCallback(
    (cb: () => void) => {
      validation(state)
        .then(state => saveProgram(state, cb))
        .catch(msg => toast.info(msg));
    },
    [state]
  );

  return {
    state,
    onChange,
    submitForm,
    updateImage,
    updatePractices,
    removePractice,
    setDescription,
    setDocuments,
    loading: saving,
    isModalOpen: active,
    openModal: activate,
    closeModal: disable
  };
};

function validation(state: FormProgram) {
  let message: any = null;

  if (state.title.length <= 2) {
    message = "Insira um titulo para esta Prática";
  } else if (state.description.length <= 2) {
    message = "Insira uma descrição para esta Prática";
  } else if (state.practice_ids.length === 0) {
    message = "Insira pelo menos uma Prática neste Programa";
  }

  if (message) {
    return Promise.reject(message);
  }

  return Promise.resolve(state);
}

function getInitialState(state: IProgram): FormProgram {
  const { _id, description, title, practices, practice_ids, image_url } = state;

  const hasImage = "image_url" in state && image_url.original;

  const _state = {
    ...initialState,
    ...(_id && { _id }),
    ...(title && { title }),
    ...(description && { description }),
    ...(practice_ids && { practice_ids }),
    ...(practices && { practices }),
    ...(hasImage && { image: image_url.original }),
    isNew: false
  } as FormProgram;

  return _state;
}
