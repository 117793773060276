import React, { useCallback } from "react";
import { usePrograms } from "../../../context/Programs";
import styled from "@emotion/styled";

interface Props {
  selectedId: string;
  updateCallback(id: string): void;
}

export const Categorys: React.SFC<Props> = ({ selectedId, updateCallback }) => {
  const {
    state: { categorys }
  } = usePrograms();

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    updateCallback(value);
  }, []);

  return (
    <Container>
      {categorys.map(option => (
        <Item key={option._id}>
          <Input
            hidden
            type="radio"
            id={option._id}
            value={option._id}
            onChange={onChange}
            checked={selectedId === option._id}
          />
          <Label htmlFor={option._id}>{option.name}</Label>
        </Item>
      ))}
    </Container>
  );
};

const Container = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: wrap;
  align-items: center;
`;

const Item = styled.li`
  white-space: nowrap;
  margin: 0 0 0.4;

  &:not(:last-child) {
    margin-right: 0.4rem;
  }
`;

const Input = styled.input`
  display: none;

  &:checked + label {
    color: #fff;
    background-color: #3273dc;
    border-color: #e0e0e0;
    box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.3);
  }
`;

const Label = styled.label`
  display: block;
  cursor: pointer;
  text-align: center;
  line-height: 35px;
  border-radius: 25px;
  min-width: 36px;
  font-size: 14px;
  font-weight: 500;
  border-style: none;
  border-width: 2px;
  padding: 0 16px;
  color: #616161;
  background-color: #eeeeee;
  border-color: #f5f5f5;
  white-space: nowrap;
  transition: all 0.2s ease;
`;
