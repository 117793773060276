import React from "react";
import LOGO from "../../assets/images/logo_black.png";
import { keyframes } from "@emotion/core";
import styled from "@emotion/styled";

export const Fallback = () => (
  <div
    style={{
      display: "flex",
      background: "#fff",
      position: "relative",
      minHeight: "100vh"
    }}
  >
    <Progress />
    <img src={LOGO} alt="LOGO" width="150px" style={{ margin: "auto" }} />
  </div>
);

const indeterminate = keyframes`
	0% {
		left: -35%;
		right: 100%;
    }

	60% {
		left: 100%;
		right: -90%
	}

	100% {
		left: 100%;
		right: -90%
	}
`;

const indeterminate_short = keyframes`
	0% {
		left: -200%;
		right: 100%
	}
	60% {
		left: 107%;
		right: -8%
	}

	100% {
		left: 107%;
		right: -8%
	}
`;

const Progress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: grey;
  /* transition: all 300ms linear; */

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    animation: ${indeterminate} 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  }

  &::after {
    animation: ${indeterminate_short} 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation-delay: 1.15s;
  }
`;
