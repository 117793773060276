import React from "react";

import showdown from "showdown";

const Converter = new showdown.Converter({
  openLinksInNewWindow: true,
  simpleLineBreaks: true,
  emoji: true
});

interface Props {
  value: string;
}

export const TextView: React.SFC<Props> = React.memo(({ value }) => {
  const _innerHtml = Converter.makeHtml(value);

  return (
      <div className="content" dangerouslySetInnerHTML={{ __html: _innerHtml }} />
  );
});
