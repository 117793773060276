import React, { useCallback, useContext } from "react";
import { Redirect } from "react-router";
import styled from "@emotion/styled";

import { FaEnvelope, FaLock } from "react-icons/fa";
import { useInput } from "../../utils/hooks";
import { AuthContext, useAuth } from "../../context/Auth";

// import LOGO from "../../assets/images/otteto_logo.svg"
import LOGO from "../../assets/images/logo-new.png";

export const Login = () => {
  const {
    state: { user },
    action: { login }
  } = useAuth();

  const email = useInput("");
  const password = useInput("");

  const submit = useCallback(
    (e: React.FormEvent<any>) => {
      e.preventDefault();
      login(email.value, password.value);
    },
    [email.value, password.value]
  );

  return user ? (
    <Redirect to="/programs" />
  ) : (
    <Container>
      <div className="box">
        <LogoContainer>
          <img src={LOGO} alt="Logo" width="300px" height="auto" />
        </LogoContainer>
        <form onSubmit={submit}>
          <div className="field">
            <p className="control has-icons-left">
              <input className="input" type="email" placeholder="Email" value={email.value} onChange={email.onChange} />
              <span className="icon is-small is-left">
                <FaEnvelope />
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                className="input"
                type="password"
                placeholder="Password"
                value={password.value}
                onChange={password.onChange}
              />
              <span className="icon is-small is-left">
                <FaLock />
              </span>
            </p>
          </div>
          <div className="field">
            <div className="control">
              <button className="button is-fullwidth">Entrar</button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  & > .box {
    min-width: 340px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`;
