import React, { useState, useCallback } from "react";
import { Container } from "../common";
import { usePrograms } from "../../context/Programs";
import { FaEdit, FaSave, FaTrash } from "react-icons/fa";
import { useInput } from "../../utils/hooks";
import { cx } from "../../utils/classnames";
import { CategoryForm } from "./newCategory";

export const Categorys = () => {
  const {
    state: { categorys, loading },
    action: { updateCategory, deleteCategory }
  } = usePrograms();

  const { onChange, value } = useInput("");
  const [selectedId, setSelection] = useState("");

  const [clickCount, setCount] = useState(0);
  const onSelected = useCallback(
    (id: string) => () => {
      setSelection(id);
      setCount(0);
    },
    []
  );

  const unSelected = useCallback(() => {
    setTimeout(() => {
      setSelection("");
      setCount(0);
    }, 15000);
  }, []);

  const remove = useCallback(() => {
    if (clickCount === 2) {
      deleteCategory(selectedId, () => setCount(0));
    } else setCount(prev => prev + 1);
  }, [selectedId, clickCount]);

  const update = useCallback(
    (e: React.FormEvent<any>) => {
      e.preventDefault();
      if (selectedId && value) {
        updateCategory(value, selectedId, () => setSelection(""));
      }
    },
    [value, selectedId]
  );

  return (
    <Container>
      <CategoryForm />
      {categorys.map(category => (
        <div className="media" key={category._id}>
          <div className="media-content">
            <div className="field">
              <div className="control">
                {selectedId === category._id ? (
                  <form onSubmit={update}>
                    <input
                      type="text"
                      className="input"
                      defaultValue={category.name}
                      onChange={onChange}
                      onBlur={unSelected}
                      autoFocus
                    />
                  </form>
                ) : (
                  <h1 className="title is-6 is-marginless">{category.name}</h1>
                )}
              </div>
            </div>
          </div>
          <div className="media-right">
            {selectedId === category._id ? (
              <div className="buttons">
                <button
                  onClick={update}
                  className={cx("button is-link", {
                    "is-loading": loading
                  })}
                >
                  <span className="icon">
                    <FaSave />
                  </span>
                  <span>Salvar</span>
                </button>
                <button
                  onClick={remove}
                  className={cx("button", {
                    "is-light": clickCount === 0,
                    "is-warning": clickCount === 1,
                    "is-danger": clickCount === 2
                  })}
                >
                  <span className="icon">
                    <FaTrash />
                  </span>
                  <span>Excluir</span>
                </button>
              </div>
            ) : (
              <button className="button is-light" onClick={onSelected(category._id)}>
                <span className="icon">
                  <FaEdit />
                </span>
                <span>Editar</span>
              </button>
            )}
          </div>
        </div>
      ))}
      {categorys.length === 0 && (
        <h2 className="title is-5 has-text-grey-light has-text-centered">Nenhuma Categoria Cadastrada.</h2>
      )}
    </Container>
  );
};
