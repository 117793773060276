import { useState, useCallback, useEffect } from "react";
import {
  Practice,
  PracticeType,
  IProgram,
  ICourse
} from "../../context/Programs/program.type";
import { usePrograms } from "../../context/Programs";
import { useCancelablePromise, useToggle } from "../../utils/hooks";
import { toast } from "react-toastify";
import { IDocument } from "../../context/Programs/document.type";
import { getDocuments } from "../../context/Programs/utils";

export interface FormCourse {
  _id: string;
  title: string;
  type: PracticeType;
  description: string;
  image: File | string | null;
  isNew: boolean;
}

const initialState: FormCourse = {
  _id: "",
  title: "",
  type: "free",
  description: "",
  image: null,
  isNew: true
};

export const useStateForm = (id?: string) => {
  const [state, setState] = useState<FormCourse>(initialState);

  const {
    state: { saving },
    action: { getCourse, saveCourse }
  } = usePrograms();

  useCancelablePromise(
    () => getCourse(id),
    data => setState(getInitialState(data))
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value, name } = e.target;
      setState(prev => ({ ...prev, [name]: value }));
    },
    []
  );

  const setDescription = useCallback((description: string) => {
    setState(prev => {
      return { ...prev, description };
    });
  }, []);

  const updateImage = useCallback((image: File) => {
    setState(prev => ({ ...prev, image }));
  }, []);

  const submitForm = useCallback(
    (cb: () => void) => {
      validation(state)
        .then(state => saveCourse(state, cb))
        .catch(msg => toast.info(msg));
    },
    [state]
  );

  return {
    state,
    onChange,
    submitForm,
    updateImage,
    setDescription,
    loading: saving,
  };
};

function validation(state: FormCourse) {
  let message: any = null;

  if (state.title.length <= 2) {
    message = "Insira um titulo para esta Prática";
  } else if (state.description.length <= 2) {
    message = "Insira uma descrição para esta Prática";
  }

  if (message) {
    return Promise.reject(message);
  }

  return Promise.resolve(state);
}

function getInitialState(state: ICourse): FormCourse {
  const { _id, description, title, image_url } = state;

  const hasImage = "image_url" in state && image_url.original;

  const _state = {
    ...initialState,
    ...(_id && { _id }),
    ...(title && { title }),
    ...(description && { description }),
    ...(hasImage && { image: image_url.original }),
    isNew: false
  } as FormCourse;

  return _state;
}
