import React, { useContext, useCallback } from "react";
import { cx } from "../../utils/classnames";
import { useUsers } from "../../context/Users";
import { useInput } from "../../utils/hooks";

export const Search = () => {
  const {
    state: { loading, account_type },
    action: { searchUser, filterByAccount }
  } = useUsers();

  const { value, onChange } = useInput("");
  const submit = useCallback(
    (e: React.FormEvent<any>) => {
      e.preventDefault();
      searchUser(value);
    },
    [value]
  );

  return (
    <div className="level is-mobile">
      <div className="level-left">
        <div className="level-item">
          <form className="field has-addons" onSubmit={submit}>
            <p className="control">
              <input
                className="input"
                type="text"
                placeholder="Buscar usuário"
                value={value}
                onChange={onChange}
              />
            </p>
            <p className="control">
              <button
                className={cx("button is-link has-text-weight-semibold", {
                  "is-loading": loading
                })}
              >
                buscar
              </button>
            </p>
          </form>
        </div>
      </div>
      <div className="level-right">
        <div className="level-item">
          <div className="buttons has-addons">
            <button
              onClick={() => filterByAccount()}
              className={cx("button has-text-weight-semibold", {
                "is-link": !account_type
              })}
            >
              Todos
            </button>
            <button
              onClick={() => filterByAccount("free")}
              className={cx("button has-text-weight-semibold", {
                "is-link": account_type === "free"
              })}
            >
              Free
            </button>
            <button
              onClick={() => filterByAccount("paid")}
              className={cx("button has-text-weight-semibold", {
                "is-link": account_type === "paid"
              })}
            >
              Pago
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
