import React, { useCallback } from "react"

import styled from "@emotion/styled"
import { FaPlus, FaTimes } from "react-icons/fa"
import css from "@emotion/css"
import { useInput, useToggle } from "../../utils/hooks"
import { Collapse } from "../../components/Collapse"
import { usePrograms } from "../../context/Programs"
import { cx } from "../../utils/classnames"

export const CategoryForm: React.SFC = () => {
  const {
    state: { saving },
    action: { createCategory }
  } = usePrograms()

  const { toggle, active, disable } = useToggle(false)
  const { value, onChange } = useInput("")

  const submit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault()
      createCategory(value, disable)
    },
    [value]
  )

  return (
    <React.Fragment>
      <div className="buttons is-right">
				{
					active ? (
        <button
          onClick={toggle}
          disabled={saving}
          className={cx("button is-link has-text-weight-semibold", { "is-loading": saving })}
        >
          <span className="icon">
            <FaTimes />
          </span>
          <span>Cancelar</span>
        </button>

					) : (
						<button
          onClick={toggle}
          disabled={saving}
          className={cx("button is-link has-text-weight-semibold", { "is-loading": saving })}
        >
          <span className="icon">
            <FaPlus />
          </span>
          <span>Adicionar</span>
        </button>
					)
				}
      </div>
      <Collapse visible={active}>
        <div className="media" style={{ marginBottom: 16 }}>
          <div className="media-content">
            <div className="field">
              <label className="label">{value || "Nova Categoria"}</label>
              <form onSubmit={submit} className="control">
                <input
                  type="text"
                  className="input"
									placeholder="Nova Categoria"
                  onChange={onChange}
									autoFocus
                />
              </form>
            </div>
          </div>
        </div>
      </Collapse>
    </React.Fragment>
  )
}

interface InputProps {
  visible: boolean
}

const visible = (props: InputProps) =>
  props.visible &&
  css`
    pointer-events: initial;
    transform: translate(0);
    opacity: 1;
  `

const Input = styled.input<InputProps>`
  transform: translateX(50%);
  max-width: 500px;
  margin-right: 1rem;
  pointer-events: none;
  opacity: 0;
  transition: all 400ms ease;
  ${visible}
`

Input.defaultProps = { className: "input" }

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
`
