import React, { useState, useCallback } from "react";
import styled from "@emotion/styled";

import { Link } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { FaPen, FaTrash } from "react-icons/fa";
import { usePrograms } from "../../context/Programs";
import { Player } from "./player";
import { Practice as IPractice } from "../../context/Programs/program.type";
import { useCancelablePromise } from "../../utils/hooks";
import { TextView } from "../../components/Editor";
import { PracticeType, PracticeVibility } from "../Practices/practiceList";
import { cx } from "../../utils/classnames";

interface Props extends RouteComponentProps<{ practiceId: string }> {}

export const Practice: React.SFC<Props> = ({ history, match: { params } }) => {
  const {
    state: { loading },
    action: { getPractice, deletePractice }
  } = usePrograms();

  const [practice, setPractice] = useState<IPractice | null>(null);

  useCancelablePromise(() => getPractice(params.practiceId), setPractice, () =>
    history.replace("/practices")
  );

  const hasImage = practice && practice.image_url && practice.image_url.original;
  const hasCategoryName = practice && practice.practice_category_name;

  const _deletePractice = () => {
    if (practice) {
      deletePractice(practice._id, () => {
        history.replace("/practices")
      })
    }
  }
  
  return practice ? (
    <div className="container is-fluid">
      <h2 className="title is-3 has-text-white">{practice.title}</h2>
      <div className="columns">
        <div className="column is-narrow">
          <Box>
            <ImageContainer>
              {hasImage && <img src={practice.image_url.original} alt="ahhh" />}
            </ImageContainer>
          </Box>
        </div>
        <div className="column">
          <div className="buttons is-right">
            <Link
              to={{
                pathname: `/practices/edit/${practice._id}`,
                state: { practice }
              }}
              className="button is-light"
            >
              <span className="icon">
                <FaPen />
              </span>
              <span>Editar</span>
            </Link>
            <button className="button has-text-weight-semibold" disabled={loading} onClick={_deletePractice}>
              <span className="icon">
                <FaTrash />
              </span>
              <span>Excluir</span>
            </button>
          </div>

          <Container>
            <label className="label">Visibilidade</label>
            <PracticeVibility only_program={practice.only_program} />
          </Container>

          <Container>
            <label className="label">Tipo</label>
            <PracticeType type={practice.type} />
          </Container>

          <Container>
            <label className="label">Categoria</label>
            <span
              className={cx("tag is-link has-text-weight-semibold", {
                "is-danger": !hasCategoryName
              })}
            >
              {hasCategoryName ? practice.practice_category_name : "Sem Categoria"}
            </span>
          </Container>

          <Container>
            <label className="label">Autor</label>
            <p className="subtitle">{practice.author}</p>
          </Container>

          <Container>
            <label className="label">Audio</label>
            {!!practice.audio ? (
              <Player file={practice.audio.url} filename={practice.audio.filename} />
            ) : (
              <article className="message is-warning">
                <div className="message-body">Não há Audio Cadastrado nesta Prática.</div>
              </article>
            )}
          </Container>

          <Container>
            <label className="label">Descrição</label>
            <TextView value={practice.description} />
          </Container>
        </div>
      </div>
    </div>
  ) : null;
};

const Container = styled.div`
  margin-bottom: 3.5rem;
`;

export const ImageContainer = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e0e0e0;
  height: 128px;
  width: 128px;
`;

export const Box = styled.div`
  background-color: white;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  border-radius: 6px;
  color: #4a4a4a;
  display: block;
  padding: 0.65rem;
  width: 100%;
`;

const Table = styled.table`
  & td {
    border: none;
    cursor: pointer;
  }
`;

Table.defaultProps = { className: "table is-hoverable" };
