import React from "react";
import { UserTable } from "./table";

export const PanelUsers = () => {
  return (
    <div className="container is-fluid">
      <div className="columns is-multiline">
        <div className="column is-12">
          <h1 className="title is-3 has-text-white">Usuários</h1>
          <UserTable />
        </div>
      </div>
    </div>
  );
};
