import React from "react"
import { Categorys } from "./categorys"

export const Categories = () => {
  return (
    <div className="container is-fluid">
      <div className="columns is-multiline">
        <div className="column is-12">
          <h1 className="title is-3 has-text-white">Categorias</h1>
          <Categorys />
        </div>
      </div>
    </div>
  )
}
