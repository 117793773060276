import React from "react";
import styled from "@emotion/styled";
import { RouteComponentProps } from "react-router";
import { FaSave, FaPlus } from "react-icons/fa";
import { cx } from "../../utils/classnames";
import { SelectPracticeModal } from "./Modal";
import { useStateForm } from "./state";
import { Practice } from "../Practices/practiceList";
import { TextEditor } from "../../components/Editor";
import { CheckBox } from "../../components/CheckBox";
import { FileUpload } from "../../components/FileUpload";
import { typeOptions } from "../../context/Programs/utils";
import { Documents } from "./documents";
import { ImageInput } from "../PracticeEdit/imageInput";
//@ts-ignore
import ReactDragListView from "react-drag-listview/lib/index.js";

interface Props extends RouteComponentProps<{ courseId: string, programId: string }> { }

export const ProgramEdit: React.SFC<Props> = ({
  history,
  match: { params }
}) => {
  const {
    state,
    loading,
    onChange,
    openModal,
    submitForm,
    setDocuments,
    updateImage,
    removePractice,
    setDescription,
    updatePractices,
    isModalOpen,
    closeModal
  } = useStateForm(params.courseId, params.programId);

  const submit = () => {
    submitForm(() => {
      history.goBack();
    });
  };


  const dragProps = {
    onDragEnd(fromIndex: any, toIndex: any) {
      const data = state.practice_ids;
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      updatePractices(data);
    },
    nodeSelector: 'li',
    handleSelector: 'a'
  };

  return (
    <React.Fragment>
      <div className="container is-fluid">
        <h2 className="title is-3 has-text-white">{state.title || "Titulo"}</h2>
        <div className="columns">
          <div className="column is-narrow">
            <ImageInput image={state.image} updateCallback={updateImage} />
          </div>

          <div className="column">
            <div className="buttons is-right">
              <button
                className={cx("button is-light", { "is-loading": loading })}
                onClick={submit}
              >
                <span className="icon">
                  <FaSave />
                </span>
                <span>Salvar</span>
              </button>
            </div>

            <Container>
              <label className="label">Tipo</label>
              <CheckBox
                name="type"
                options={typeOptions}
                value={state.type}
                onChange={onChange}
              />
            </Container>

            <Container>
              <label className="label">Titulo</label>
              <div className="control">
                <input
                  name="title"
                  className="input is-fullwidth"
                  placeholder="Titulo"
                  onChange={onChange}
                  value={state.title}
                />
              </div>
            </Container>

            <Container>
              <label className="label">Descrição</label>
              <TextEditor
                initialValue={state.description}
                updateCallback={setDescription}
              />
            </Container>

            <Container>
              <label className="label">Práticas</label>
              {state.practices!.length ? (
                <ReactDragListView {...dragProps}>
                  <ol>
                    {state.practices.map(practice => (
                      <li key={practice._id}>
                        <Practice key={practice._id} practice={practice} sortable>
                          <div className="media-right">
                            <button
                              className="delete"
                              onClick={removePractice(practice._id)}
                            />
                          </div>
                        </Practice>
                      </li>
                    ))}
                  </ol>
                </ReactDragListView>
              ) : (
                <article className="message is-warning">
                  <div className="message-body">
                    <strong>
                      Cadastre no mínimo uma Prática neste Programa.
                    </strong>
                  </div>
                </article>
              )}

              <div className="buttons is-right">
                <button
                  className="button is-link has-text-weight-semibold"
                  onClick={openModal}
                >
                  <span className="icon">
                    <FaPlus />
                  </span>
                  <span>Adicionar</span>
                </button>
              </div>
            </Container>

            <Container>
              <label className="label">Apostilas</label>
              {state.showDocuments && (
                <Documents
                  docs={state.documents}
                  updateCallback={setDocuments}
                />
              )}
            </Container>
          </div>

          {/* <div className="column is-12"> */}
          {/* <div className="buttons is-right">
              <button className={cx("button is-light", { "is-loading": loading })} onClick={submit}>
                <span className="icon">
                  <FaSave />
                </span>
                <span>Salvar</span>
              </button>
            </div> */}
          {/* </div> */}
          {/* <div className="column is-12"> */}

          {/* </div> */}
        </div>
      </div>
      <SelectPracticeModal
        updateCallback={updatePractices}
        selection={state.practice_ids}
        isOpen={isModalOpen}
        close={closeModal}
      />
    </React.Fragment>
  );
};

const Container = styled.div`
  margin-bottom: 3rem;
`;

const Table = styled.table`
  & td {
    border: none;
    cursor: pointer;
  }
`;

Table.defaultProps = { className: "table is-hoverable" };
