import React from "react";
import styled from "@emotion/styled";

export const Preview = React.memo(({ image }: { image: any }) => {
  const imageUrl = () => {
    let url = image || "https://via.placeholder.com/128";
    if (image instanceof File) {
      url = URL.createObjectURL(image);
    }
    return <img src={url} alt="Image" />;
  };

  return <ImageContainer>{imageUrl()}</ImageContainer>;
});

const ImageContainer = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e0e0e0;
  height: 128px;
  width: 128px;
`;
