import React, { useMemo, useCallback } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { cx } from "../../utils/classnames"

interface Props {
  total: number
  currentPage: number
  itemsPerPage: number
  getPage(page: number): void
  changePageSize?(page: number): void
}

export const Pagination = ({
  itemsPerPage,
  getPage,
  total,
  currentPage,
  changePageSize
}: Props) => {
  const goToPage = useCallback((page: number) => () => getPage(page), [])
  const onChangePageSize = useCallback((e: React.ChangeEvent<any>) => {
    changePageSize && changePageSize(e.target.value)
  }, [])

  const pagesTotal = Math.ceil(total / itemsPerPage)
  const buttonstotal = pagesTotal > 5 ? 5 : pagesTotal

  const buttons = useMemo(() => {
    let firstButton = currentPage - Math.floor(buttonstotal / 2)
    let lastButton = firstButton + (buttonstotal - Math.ceil(buttonstotal % 2))

    if (firstButton < 1) {
      firstButton = 1
      lastButton = firstButton + (buttonstotal - 1)
    }

    if (lastButton > pagesTotal) {
      lastButton = pagesTotal
      firstButton = lastButton - (buttonstotal - 1)
    }

    const pages = []
    for (let page = firstButton; page <= lastButton; page++) {
      pages.push(page)
    }

    return pages
  }, [currentPage, buttonstotal])

  const showFirstPageButton = buttons[0] > 1
  const showLastpageButton = buttons[buttons.length - 1] < pagesTotal

  return total && buttonstotal > 1 ? (
    <nav className="pagination is-right" role="navigation" aria-label="pagination">
      {changePageSize && (
        <div className="select">
          <select onChange={onChangePageSize}>
            <option value={10}># Paginas: 10</option>
            <option value={15}># Paginas: 15</option>
            <option value={20}># Paginas: 20</option>
            <option value={25}># Paginas: 25</option>
            <option value={20}># Paginas: 30</option>
          </select>
        </div>
      )}

      <AnchorButton
        onClick={goToPage(currentPage - 1)}
        className="pagination-previous"
        disabled={currentPage < 2}
      >
        Anterior
      </AnchorButton>
      <AnchorButton
        onClick={goToPage(currentPage + 1)}
        className="pagination-next"
        disabled={currentPage > pagesTotal - 1}
      >
        Próximo
      </AnchorButton>
      <ul className="pagination-list">
        {showFirstPageButton && (
          <li>
            <a
              className={cx("pagination-link", { "is-current": currentPage === 1 })}
              onClick={goToPage(1)}
            >
              1
            </a>
          </li>
        )}

        {buttons[0] > 2 && (
          <li>
            <span className="pagination-ellipsis">&hellip;</span>
          </li>
        )}

        {buttons.map(button => (
          <li key={button}>
            <a
              className={cx("pagination-link", { "is-current": currentPage === button })}
              onClick={goToPage(button)}
            >
              {button}
            </a>
          </li>
        ))}

        {buttons[buttons.length - 1] < pagesTotal - 1 && (
          <li>
            <span className="pagination-ellipsis">&hellip;</span>
          </li>
        )}

        {showLastpageButton && (
          <li>
            <a
              className={cx("pagination-link", { "is-current": currentPage === pagesTotal })}
              onClick={goToPage(pagesTotal)}
            >
              {pagesTotal}
            </a>
          </li>
        )}
      </ul>
    </nav>
  ) : null
}

interface IAnchorButton {
  disabled: boolean
}

const disabled = (props: IAnchorButton) =>
  props.disabled &&
  css`
    pointer-events: none;
    cursor: not-allowed;
  `

const AnchorButton = styled.a<IAnchorButton>`
  ${disabled}
`
