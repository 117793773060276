import React, { useContext } from "react";
import { toast } from "react-toastify";
import produce from "immer";
import {
  ResponseWithPractices,
  ResponseWithPrograms,
  ResponseWithPractice,
  ResponseWithProgram,
  ResponseWithCategory,
  ResponseWithCategorys,
  Response,
  ResponseWithCourses,
  ResponseWithCourse
} from "../response.type";
import { transformPractice, transformProgram, uploadFile, saveDocuments, transformCourse } from "./utils";
import { Practice, Program, IProgram, Category, ICourse } from "./program.type";
import { FormPractice } from "../../view/PracticeEdit/state";
import { FormProgram } from "../../view/ProgramEdit/state";
import { client } from "../client";
import { FormCourse } from "../../view/CourseEdit/state";

interface IContext {
  state: State;
  action: {
    getPractice(id?: string): Promise<Practice | null>;
    getProgram(id?: string): Promise<IProgram | null>;
    getCourse(id?: string): Promise<ICourse | null>;
    getPraticesById(ids: string[]): Practice[];
    savePractice(form: FormPractice, callback?: () => void): void;
    saveCourse(form: FormCourse, callback?: () => void): void;
    saveProgram(form: FormProgram, callback?: () => void): void;
    getPracticesFromProgram(programId: string): Practice[];
    deletePractice(id: string, cb: () => void): void;
    deleteCourse(id: string, cb: () => void): void;
    deleteProgram(id: string, cb: () => void): void;
    createCategory(category: string, cb?: () => void): void;
    updateCategory(category: string, id: string, cb?: () => void): void;
    deleteCategory(id: string, cb?: () => void): void;
  };
}

export const PlaceContext = React.createContext({} as IContext);

interface State {
  [key: string]: any;

  loading: boolean;
  saving: boolean;
  practices: Practice[];
  courses: ICourse[];
  programs: Program[];
  categorys: Category[];
}

export const usePrograms = () => {
  const context = useContext(PlaceContext);
  if (context === undefined) {
    throw new Error("usePlace must be used within a AuthProvider");
  }
  return context;
};

export class PlaceProvider extends React.PureComponent<{}, State> {
  state = {
    loading: false,
    saving: false,
    practices: [],
    courses: [],
    programs: [],
    categorys: [],
    // For pagination usage
    stats: {
      places: 0,
      users: 0,
      schedule_done: 0,
      schedule_active: 0
    }
  } as State;

  componentDidMount() {
    this._bootstrapAppData();
  }

  getPractice = async (id?: string): Promise<Practice | null> => {
    if (!id) return Promise.reject(null);

    let _practice = null as Practice | any;

    try {
      if (this.state.practices.length > 0) {
        const practice = this.state.practices.find(p => p._id === id);
        _practice = practice;
      } else {
        const response = await client.get<ResponseWithPractice>(`practices/${id}`);
        _practice = response.success ? response.data : null;
      }

      if (_practice) {
        const categoryId = _practice.practice_category_id;
        const category = await this.getCategoryById(categoryId);
        if (category) {
          _practice.practice_category_name = category.name;
        }

        return _practice;
      }
    } catch (err) {
      console.log(err);
    }

    return Promise.reject(null);
  };

  getCourse = async (id?: string): Promise<ICourse | null> => {
    if (!id) return Promise.reject(null);

    try {
      const response = await client.get<ResponseWithCourse>(`courses/${id}`);
      if (response.success) {
        return response.data;
      }
    } catch (err) {
      console.log(err);
    }

    return Promise.reject(null);
  };

  getProgram = async (id?: string): Promise<IProgram | null> => {
    if (!id) return Promise.reject(null);

    let program = {} as IProgram | any;

    try {
      const response = await client.get<ResponseWithProgram>(`programs/${id}`);
      if (response.success) {
        program = response.data;
      }

      program.practices = this.getPracticesById(program.practice_ids);
      return program;
    } catch (err) {
      console.log(err);
    }

    return Promise.reject(null);
  };

  getCategoryById = async (categoryId: string): Promise<Category | any> => {
    let _category = undefined as Category | any;
    try {
      if (this.state.categorys.length > 0) {
        const category = this.state.categorys.find(c => c._id === categoryId);
        _category = category;
      } else {
        const response = await client.get<ResponseWithCategory>(
          `practiceCategories/${categoryId}`
        );
        _category = response.success ? response.data : null;
      }

      return _category;
    } catch (err) {
      console.log(err);
    }
  };

  getPracticesFromProgram = (programId: string): Practice[] => {
    const program = this.state.programs.find(p => p._id === programId);

    if (!program) return [];
    const ids = program.practice_ids;

    if (!Array.isArray(ids)) return [];

    return this.getPracticesById(ids);
  };

  getPracticesById = (ids: string[]): Practice[] => {
    let practices: Practice[] = [];

    ids.forEach(id => {
      const practice = this.state.practices.find(p => p._id === id);
      if (practice) practices.push(practice);
    });

    return practices;
  };

  _bootstrapAppData = () => {
    this.setState({ loading: true }, async () => {
      try {
        const [practices, courses, programs, categorys] = await Promise.all([
          client.get<ResponseWithPractices>("practices"),
          client.get<ResponseWithCourses>("courses"),
          client.get<ResponseWithPrograms>("programs"),
          client.get<ResponseWithCategorys>("practiceCategories")
        ]);

        if (practices.success || courses.success || programs.success || categorys.success) {
          this.setState({
            loading: false,
            practices: practices.data,
            courses: courses.data,
            programs: programs.data,
            categorys: categorys.data.reverse()
          });
        }
      } catch (err) {
        console.log(err);
        this.setState({ loading: false });
      }
    });
  };

  savePractice = (form: FormPractice, callback?: () => void) => {
    const { isNew, _id, data, audio, image, audio_metadata } = transformPractice(form);

    this.setState({ saving: true }, async () => {
      try {
        const promise = isNew
          ? client.post<ResponseWithPractice>("practices", data)
          : client.put<ResponseWithPractice>(`practices/${_id}`, data);

        const response = await promise;

        if (response.success) {
          const id = response.data._id;
          const idx = this.state.practices.findIndex(p => p._id === id);

          if (image) {
            await uploadFile(image, {
              url: `practices/${id}/image`,
              name: "newPracticePicture"
            });
          }

          if (audio) {
            await uploadFile(audio, {
              url: `practices/${id}/audio`,
              name: "newPracticeAudio",
              metadata: audio_metadata
            });
          }

          this.setState(
            produce(draft => {
              draft.saving = false;
              if (idx !== -1) draft.practices[idx] = response.data;
              else draft.practices.push(response.data);
            }),
            () => callback && callback()
          );
        } else throw new Error("Não foi possivel salvar.");
      } catch (err) {
        console.log(err);
        this.setState({ saving: false }, () => toast.error('teste'));
      }
    });
  };

  saveCourse = (form: FormProgram, callback?: () => void) => {
    const { isNew, _id, data, image } = transformCourse(form);

    this.setState({ saving: true }, async () => {
      try {
        const promise = isNew
          ? client.post<ResponseWithProgram>(`courses/`, data)
          : client.put<ResponseWithProgram>(`courses/${_id}`, data);

        const response = await promise;

        if (response.success) {
          const id = response.data._id;
          const idx = this.state.courses.findIndex(p => p._id === id);
          this.setState(
            produce(draft => {
              draft.saving = false;
              if (idx !== -1) draft.courses[idx] = response.data;
              else draft.courses.push(response.data);
            })
          );

          if (image) {
            await uploadFile(image, {
              url: `courses/${id}/image`,
              name: "newCoursePicture"
            });
          }

          if (callback) callback();

        } else throw new Error("Não foi possivel salvar.");
      } catch (err) {
        console.log(err);
        this.setState({ saving: false }, () => toast.error('teste'));
      }
    });
  };

  saveProgram = (form: FormProgram, callback?: () => void) => {
    const { isNew, _id, courseId, data, documents, image } = transformProgram(form);

    this.setState({ saving: true }, async () => {
      try {
        const promise = isNew
          ? client.post<ResponseWithProgram>(`courses/${courseId}/programs`, data)
          : client.put<ResponseWithProgram>(`programs/${_id}`, data);

        const response = await promise;

        if (response.success) {
          const id = response.data._id;
          if (image) {
            await uploadFile(image, {
              url: `programs/${id}/image`,
              name: "newProgramPicture"
            });
          }

          if (callback) callback();

          saveDocuments(documents, id);
        } else throw new Error("Não foi possivel salvar.");
      } catch (err) {
        console.log(err);
        this.setState({ saving: false }, () => toast.error('teste'));
      }
    });
  };

  deletePractice = (id: string, cb: () => void) => {
    this.setState({ loading: true }, async () => {
      try {
        const response = await client.delete<Response>(`practices/${id}`);
        if (response.success) {
          this.setState(
            prev => ({
              ...prev,
              loading: false,
              practices: prev.practices.filter(p => p._id !== id)
            }),
            () => cb()
          );
        } else throw new Error("Não foi possivel Excluir");
      } catch (err) {
        this.setState({ loading: false }, () => {
          toast.error('teste');
        });
      }
    });
  };

  deleteCourse = (id: string, cb: () => void) => {
    this.setState({ loading: true }, async () => {
      try {
        const response = await client.delete<Response>(`courses/${id}`);
        if (response.success) {
          this.setState(
            prev => ({
              ...prev,
              loading: false,
              courses: prev.courses.filter(p => p._id !== id)
            }),
            () => cb()
          );
        } else throw new Error("Não foi possivel Excluir");
      } catch (err) {
        this.setState({ loading: false }, () => {
          toast.error('teste');
        });
      }
    });
  };

  deleteProgram = (id: string, cb: () => void) => {
    this.setState({ loading: true }, async () => {
      try {
        const response = await client.delete<Response>(`programs/${id}`);
        if (response.success) {
          this.setState(
            prev => ({
              ...prev,
              loading: false,
              programs: prev.programs.filter(p => p._id !== id)
            }),
            () => cb()
          );
        } else throw new Error("Não foi possivel Excluir");
      } catch (err) {
        this.setState({ loading: false }, () => {
          toast.error('teste');
        });
      }
    });
  };

  createCategory = (name: string, cb?: () => void) => {
    this._loadingState("saving", async () => {
      const response = await client.post<ResponseWithCategory>("practiceCategories", {
        name
      });
      if (response.success) {
        this.setState(
          prev => ({
            saving: false,
            categorys: [response.data, ...prev.categorys]
          }),
          () => cb && cb()
        );
      } else throw new Error("Não foi possivel salvar esta Categoria.");
    });
  };

  updateCategory = (name: string, id: string, cb?: () => void) => {
    this._loadingState("loading", async () => {
      const response = await client.put<ResponseWithCategory>(
        `practiceCategories/${id}`,
        {
          name
        }
      );
      if (response.success) {
        const idx = this.state.categorys.findIndex(c => c._id === id);
        this.setState(prev =>
          produce(
            prev,
            draft => {
              draft.loading = false;
              draft.categorys[idx] = response.data;
            },
            () => cb && cb()
          )
        );
      } else throw new Error("Não foi possivel salvar esta Categoria.");
    });
  };

  deleteCategory = (id: string, cb?: () => void) => {
    this._loadingState("loading", async () => {
      const response = await client.delete<ResponseWithCategory>(
        `practiceCategories/${id}`
      );
      if (response.success) {
        const idx = this.state.categorys.findIndex(c => c._id === id);
        this.setState(
          prev =>
            produce(prev, draft => {
              draft.loading = false;
              draft.categorys.splice(idx, 1);
            }),
          () => cb && cb()
        );
      } else throw new Error("Não foi possivel criar esta Categoria.");
    });
  };

  _loadingState = (key: string, callback: () => Promise<any>) => {
    this.setState({ [key]: true }, async () => {
      try {
        await callback();
      } catch (err) {
        console.log(err);
        this.setState({ [key]: false }, () => toast.error(err));
      }
    });
  };

  render() {
    const value = {
      state: { ...this.state },
      action: {
        getProgram: this.getProgram,
        getCourse: this.getCourse,
        getPractice: this.getPractice,
        saveCourse: this.saveCourse,
        saveProgram: this.saveProgram,
        savePractice: this.savePractice,
        getPraticesById: this.getPracticesById,
        getPracticesFromProgram: this.getPracticesFromProgram,
        deletePractice: this.deletePractice,
        deleteCourse: this.deleteCourse,
        deleteProgram: this.deleteProgram,
        updateCategory: this.updateCategory,
        createCategory: this.createCategory,
        deleteCategory: this.deleteCategory
      }
    };

    return <PlaceContext.Provider value={value} {...this.props} />;
  }
}

// functioneventUnload() {
//   return
// }
