import { useState, useCallback, useEffect, useRef, useLayoutEffect } from "react";

export const useToggle = (state: boolean) => {
  const [active, setActive] = useState(false);
  const toggle = () => setActive(v => !v);
  const activate = () => setActive(true);
  const disable = () => setActive(false);
  return { active, activate, disable, toggle };
};

export function useToggleState<T>(initial: T) {
  const [state, setState] = useState<T>(initial);
  const toggle = useCallback((s: T) => () => setState(s), []);
  return { state, toggle };
}

export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value]);

  return debouncedValue;
};

export function useDebounceCallback<T>(value: T, delay: number, cb: (t: T) => any) {
  const didMount = useRef(false);
  const timeout = useRef<any>(0);

  useEffect(() => {
    if (didMount.current) {
      timeout.current = setTimeout(() => cb(value), delay);
    } else didMount.current = true;

    return () => {
      clearTimeout(timeout.current);
    };
  }, [value]);
}

export function useDebounceValue<T, S>(value: S, data: T, delay: number, cb: (value: S, t: T) => T): T {
  const didMount = useRef(false);
  const timeout = useRef<any>(0);

  const [_state, setState] = useState(data);

  useEffect(() => {
    if (didMount.current) {
      timeout.current = setTimeout(() => setState(cb(value, data)), delay);
    } else didMount.current = true;

    return () => {
      clearTimeout(timeout.current);
    };
  }, [value, data]);

  return _state;
}

export function useInput(initialState: string) {
  const [value, setValue] = useState<string>(initialState);
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => setValue(e.target.value),
    []
  );

  return {
    value,
    setValue,
    onChange
  };
}

export function useCancelablePromise<T>(promise: () => Promise<T>, callback: (v: any) => void, failed?: () => any) {
  useEffect(() => {
    let shouldContinue = true;

    promise()
      .then(value => (shouldContinue ? callback(value) : null))
      .catch(() => failed && failed());

    return () => {
      shouldContinue = false;
    };
  }, []);
}

function useIsMounted() {
  const mounted = useRef(false);

  useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return mounted.current;
}

export function useMedia(size: number) {
  const [value, setValue] = useState<boolean>(() => {
    return window.innerWidth <= size;
  });

  const toggle = useCallback(() => setValue(p => !p), []);

  return { value, toggle };
}
